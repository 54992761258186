import { TreeView } from "@mui/x-tree-view/TreeView";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { FaHandshake } from "react-icons/fa";
import { Link } from "react-router-dom";
import AdjustIcon from "@mui/icons-material/Adjust";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import LocationIcon from "@mui/icons-material/LocationOn";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { IoIosInformationCircle } from "react-icons/io";
import StoreIcon from "@mui/icons-material/Store";
import { LiaMoneyCheckSolid } from "react-icons/lia";
import { MdOutlinePolicy } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { AiOutlineDollar } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { GoProjectSymlink } from "react-icons/go";
import PeopleSharpIcon from "@mui/icons-material/PeopleSharp";
import AccountBalanceSharpIcon from "@mui/icons-material/AccountBalanceSharp";
import AccountBalanceWalletSharpIcon from "@mui/icons-material/AccountBalanceWalletSharp";
import { IoIosCreate } from "react-icons/io";
import { GrTemplate } from "react-icons/gr";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DnsIcon from "@mui/icons-material/Dns";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import BarChartIcon from "@mui/icons-material/BarChart";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import FolderIcon from "@mui/icons-material/Folder";
import { FaUserTag } from "react-icons/fa";
import PaymentsIcon from "@mui/icons-material/Payments";
import { IoMdFingerPrint } from "react-icons/io";
import { FaMinus } from "react-icons/fa6";
import { FaUsersLine } from "react-icons/fa6";
import { FaProjectDiagram, FaUserTie } from "react-icons/fa";
import { MdOutlineReceipt } from "react-icons/md";
import { AiOutlineBarChart } from "react-icons/ai";
import { BiBuildings } from "react-icons/bi";
import { MdOutlineDashboard } from "react-icons/md";
// import { FaStore } from "react-icons/fa";
// import { IoIosInformationCircleOutline } from "react-icons/io";
// import FmdGoodIcon from "@mui/icons-material/FmdGood";
// import MapIcon from "@mui/icons-material/Map";
// import { PiBuildingOffice } from "react-icons/pi";
// import { FaMoneyBill1Wave } from "react-icons/fa6";
// import { AiTwotoneDollar } from "react-icons/ai";
// import { FcLeave } from "react-icons/fc";
// import { VscChecklist } from "react-icons/vsc";
// import { ShopRemove } from "iconsax-react";
import { AES, enc } from "crypto-js";
import * as url from "../../../../../store/constant/Endpoints";
import {
  TreeItem,
  TreeItemProps,
  treeItemClasses,
} from "@mui/x-tree-view/TreeItem";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { RiArrowDownSLine } from "react-icons/ri";
import KeySharpIcon from "@mui/icons-material/KeySharp";
import { Height } from "../../../../../../node_modules/@mui/icons-material/index";
// const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
//   color: theme.palette.text.secondary,
//   [`& .${treeItemClasses.content}`]: {
//     color: theme.palette.text.secondary,
//     borderTopRightRadius: theme.spacing(3),
//     borderBottomRightRadius: theme.spacing(3),
//     borderTopLeftRadius: theme.spacing(1),
//     borderBottomLeftRadius: theme.spacing(1),
//     paddingRight: theme.spacing(0),
//     marginLeft: `${theme.spacing(1.5)} !important`,
//     // marginRight: `${theme.spacing(1)} !important`, // Add !important to force the margin
//     fontWeight: theme.typography.fontWeightMedium,
//     "&.Mui-expanded": {
//       fontWeight: theme.typography.fontWeightRegular,
//     },
//     "&": {
//       paddingTop: theme.spacing(0.5),
//       paddingBottom: theme.spacing(0.3),
//     },
//     "&:hover": {
//       backgroundColor: theme.palette.action.hover,
//     },
//     "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
//       backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
//       color: "var(--tree-view-color)",
//     },
//     [`& .${treeItemClasses.label}`]: {
//       fontWeight: "inherit",
//       color: "inherit",
//     },
//   },
//   [`& .${treeItemClasses.group}`]: {
//     marginLeft: 0,
//     [`& .${treeItemClasses.content}`]: {
//       paddingLeft: theme.spacing(0),
//     },
//   },
// }));
// const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
//   color: theme.palette.text.secondary,
//   position: 'relative', // Add position relative to the root
//   [`& .${treeItemClasses.content}`]: {
//     color: theme.palette.text.secondary,
//     borderTopRightRadius: theme.spacing(3),
//     borderBottomRightRadius: theme.spacing(3),
//     borderTopLeftRadius: theme.spacing(1),
//     borderBottomLeftRadius: theme.spacing(1),
//     paddingRight: theme.spacing(0),
//     marginLeft: `${theme.spacing(1.5)} !important`,
//     // marginRight: `${theme.spacing(1)} !important`, // Add !important to force the margin
//     fontWeight: theme.typography.fontWeightMedium,
//     "&.Mui-expanded": {
//       fontWeight: theme.typography.fontWeightRegular,
//     },
//     "&": {
//       paddingTop: theme.spacing(0.5),
//       paddingBottom: theme.spacing(0.3),
//     },
//     "&:hover": {
//       backgroundColor: theme.palette.action.hover,
//     },
//     "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
//       backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
//       color: "var(--tree-view-color)",
//       // Add a pseudo-element for the border on the right side
//       "&::after": {
//         content: '""',
//         position: 'absolute',
//         right: 0,  // Changed from 'left: 0' to 'right: 0'
//         top: 0,
//         bottom: 0,
//         width: '5px',
//         Height:'57px',
//         backgroundColor: '#E03F45',
//       }
//     },
//     [`& .${treeItemClasses.label}`]: {
//       fontWeight: "inherit",
//       color: "inherit",
//     },
//   },
//   [`& .${treeItemClasses.group}`]: {
//     marginLeft: 0,
//     [`& .${treeItemClasses.content}`]: {
//       paddingLeft: theme.spacing(0),
//     },
//   },
// }));

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  position: "relative",
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(3),
    borderBottomRightRadius: theme.spacing(3),
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    paddingRight: theme.spacing(0),
    marginLeft: `${theme.spacing(1.5)} !important`,
    fontWeight: theme.typography.fontWeightMedium,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minHeight: theme.spacing(6),
    display: "flex",
    alignItems: "center",
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: "#FAFAFA",
      color: "var(--tree-view-color)",
      "&:not(.Mui-expanded)::after, &.Mui-selected::after": {
        content: '""',
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        width: "5px",
        backgroundColor: "#ed1d24e6",
      },
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(0),
    },
  },
}));

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
  const theme = useTheme();
  const location = useLocation(); // Get current route

  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    colorForDarkMode,
    bgColorForDarkMode,
    nodeId, // Ensure nodeId is used for selection
    ...other
  } = props;

  const isSelected = location.pathname === nodeId; // Check if current path matches nodeId

  const styleProps = {
    "--tree-view-color":
      theme.palette.mode !== "dark" ? color : colorForDarkMode,
    "--tree-view-bg-color":
      theme.palette.mode !== "dark" ? bgColor : bgColorForDarkMode,
  };

  return (
    <StyledTreeItemRoot
      nodeId={nodeId}
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 1,
            pl: 0,
            color: isSelected ? "#000000" : theme.palette.text.secondary, // Active tab color
            fontFamily: "poppins",
            justifyContent: "space-between",
            // backgroundColor: isSelected ? "#FAFAFA" : "transparent", // Background color when active
            // borderRight: isSelected ? "5px solid black" : "none", // Active tab right border
          }}
        >
          <Box
            component={LabelIcon}
            color="inherit"
            sx={{ mr: 1, fontSize: "24px !important" }}
          />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1, fontSize: "14px" }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={styleProps}
      selected={isSelected} // Ensure selection is controlled
      {...other}
      ref={ref}
    />
  );
});

let assignUserChecking = localStorage.getItem("userassigncompany");

// ...(dashboard && assignUserChecking == "false" ? [dashboard] : []),

const TreeNav = () => {
  let permissionUser = localStorage.getItem("logindata");
  let decryptedData = null;
  if (permissionUser) {
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
  }

  const permissions = JSON.parse(decryptedData);
  const allowedPermissions =
    permissions && permissions.length !== 0
      ? permissions.map((permission) => permission?.permission)
      : [];
  const permissionsAccessFunction = (brand) => {
    return (
      allowedPermissions &&
      allowedPermissions.length !== 0 &&
      allowedPermissions.some((permission) => permission.includes(brand))
    );
  };
  const location = useLocation(); // Get current route

  return (
    <TreeView
      aria-label="gmail"
      defaultExpanded={[]}
      defaultSelected={""}
      defaultCollapseIcon={<MdOutlineKeyboardArrowUp />}
      defaultExpandIcon={<RiArrowDownSLine />}
      selected={location.pathname} // Directly use location.pathname
      defaultEndIcon={<div style={{ width: 24 }} />}
      style={{
        //  height: 264,
        color: "#E03F45",
        flexGrow: 1,
        maxWidth: 400,
      }}
    >

      <Link
        to={"/dashboard"}
        style={{ textDecoration: "none" }}
      >
        <StyledTreeItem
          nodeId="/dashboard" // This must match the current route
          labelText="Dashboard"
          labelIcon={MdOutlineDashboard}
        >
        </StyledTreeItem>
      </Link>

      {permissionsAccessFunction("quotation") && allowedPermissions.includes("quotation.get") && (
        <Link to="/quotation/all-quotations" style={{ textDecoration: "none" }}>
          <StyledTreeItem
            nodeId="/quotation/all-quotations"
            labelText="Quotation"
            labelIcon={ReceiptIcon}
          />
        </Link>
      )}


      {permissionsAccessFunction("invoice") && allowedPermissions.includes("invoice.get") && (
        <Link to="/invoice/all-invoices" style={{ textDecoration: "none" }}>
          <StyledTreeItem
            nodeId="/invoice/all-invoices"
            labelText="Invoice"
            labelIcon={DescriptionIcon}
          />
        </Link>
      )}


      {permissionsAccessFunction("payment") && allowedPermissions.includes("payment.getAll") && (
        <Link to="/domain-parameter/payment-voucher" style={{ textDecoration: "none" }}>
          <StyledTreeItem
            nodeId="/domain-parameter/payment-voucher"
            labelText="Voucher"
            labelIcon={ReceiptLongIcon}
          />
        </Link>
      )}

      {permissionsAccessFunction("challan") && allowedPermissions.includes("challan.get") && (
        <Link to="/delivery-note/all-challan" style={{ textDecoration: "none" }}>
          <StyledTreeItem
            nodeId="/delivery-note/all-challan"
            labelText="Delivery Note"
            labelIcon={EditNoteIcon}
          />
        </Link>
      )}

      {permissionsAccessFunction("employee_info_getAll") ||
        permissionsAccessFunction("leave_management_getAll") ||
        permissionsAccessFunction("loan_management_getAll") ||
        permissionsAccessFunction("payroll_management_getAll") ||
        permissionsAccessFunction("payroll_management_getAll") ||
        permissionsAccessFunction("attendance_management_getAll")
        ? (
          <StyledTreeItem
            nodeId="/hrm-module"
            labelText="HRM Module"
            labelIcon={FaUsersLine}
          >
            {allowedPermissions.includes("employee_info.viewAll") ? (
              <Link
                to={"/hrm-modules/employee-info"}
                style={{ textDecoration: "none" }}
              >
                <StyledTreeItem
                  nodeId="/hrm-modules/employee-info"
                  labelText="Add Employee Information"
                  labelIcon={ContentPasteIcon}
                  color="red"
                  bgColor="#FCE4E4"
                  colorForDarkMode="#E03F45"
                  bgColorForDarkMode="#E03F45"
                />
              </Link>
            ) : null}
            {allowedPermissions.includes("leave_management.viewAll") ? (
              <Link
                to="/leave-management/all-leaves"
                style={{ textDecoration: "none" }}
              >
                <StyledTreeItem
                  nodeId="/leave-management/all-leaves"
                  labelText="Create Leave"
                  labelIcon={IoIosCreate}
                  color="red"
                  bgColor="#FCE4E4"
                  colorForDarkMode="#E03F45"
                  bgColorForDarkMode="#E03F45"
                />
              </Link>
            ) : null}
            {allowedPermissions.includes("loan_management.viewAll") ? (
              <Link
                to="/loan-management/all-loans"
                style={{ textDecoration: "none" }}
              >
                <StyledTreeItem
                  nodeId="/loan-management/all-loans"
                  labelText="Add Loans"
                  labelIcon={IoIosInformationCircle}
                  color="red"
                  bgColor="#FCE4E4"
                  colorForDarkMode="#E03F45"
                  bgColorForDarkMode="#E03F45"
                />
              </Link>
            ) : null}

            {allowedPermissions.includes("loan_management.viewAll") ? (
              <Link
                to="/loan-management/loans-history"
                style={{ textDecoration: "none" }}
              >
                <StyledTreeItem
                  nodeId="/loan-management/loans-history"
                  labelText="Loan History"
                  labelIcon={IoIosInformationCircle}
                  color="red"
                  bgColor="#FCE4E4"
                  colorForDarkMode="#E03F45"
                  bgColorForDarkMode="#E03F45"
                />
              </Link>
            ) : null}
            {allowedPermissions.includes("generate_salary.viewAll") ? (
              <Link
                to={"/hrm-modules/payroll-management"}
                style={{ textDecoration: "none" }}
              >
                <StyledTreeItem
                  nodeId="/hrm-modules/payroll-management"
                  labelText="Generate Salary"
                  labelIcon={PaymentsIcon}
                  color="red"
                  bgColor="#FCE4E4"
                  colorForDarkMode="#E03F45"
                  bgColorForDarkMode="#E03F45"
                />
              </Link>
            ) : null}
            {allowedPermissions.includes("payslip.viewAll") ? (
              <Link
                to={"/hrm-modules/payroll-payslip"}
                style={{ textDecoration: "none" }}
              >
                <StyledTreeItem
                  nodeId="/hrm-modules/payroll-payslip"
                  labelText="PaySlip"
                  labelIcon={LiaMoneyCheckSolid}
                  color="red"
                  bgColor="#FCE4E4"
                  colorForDarkMode="#E03F45"
                  bgColorForDarkMode="#E03F45"
                />
              </Link>
            ) : null}
            {allowedPermissions.includes("attendance.viewAll") ? (
              <Link
                to={"/attendance-management/all-attendance"}
                style={{ textDecoration: "none" }}
              >
                <StyledTreeItem
                  nodeId="/attendance-management/all-attendance"
                  labelText="Biometric Attendance"
                  labelIcon={IoMdFingerPrint}
                  color="red"
                  bgColor="#FCE4E4"
                  colorForDarkMode="#E03F45"
                  bgColorForDarkMode="#E03F45"
                />
              </Link>
            ) : null}
          </StyledTreeItem>
        ) : null}


      {/* ----------------------------------------------------VENDORS START--------------------------------------------------------------------- */}

      {permissionsAccessFunction("allview_reports") ||
        permissionsAccessFunction("view_report_ledger") ? (
        <StyledTreeItem
          nodeId="/all-vendors"
          labelText="Vendors"
          labelIcon={FaHandshake}
        >
          {allowedPermissions.includes("allview_reports") ? (
            <Link
              to={"/vendors/all-vendors"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/vendors/all-vendors"
                labelText="Vendors"
                labelIcon={FaHandshake}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
        </StyledTreeItem>
      ) : null}


      {/* ----------------------------------------------------VENDORS END --------------------------------------------------------------------- */}





      {permissionsAccessFunction("allview_reports") ||
        permissionsAccessFunction("view_report_ledger") ? (
        <StyledTreeItem
          nodeId="/all-center-pointssss"
          labelText="Reports"
          labelIcon={MultilineChartIcon}
        >
          {allowedPermissions.includes("allview_reports") ? (
            <Link
              to={"/reports/all-reports"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/reports/all-reports"
                labelText="Reports"
                labelIcon={BarChartIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes("view_report_ledger") ? (
            <Link to={"/reports/ledger"} style={{ textDecoration: "none" }}>
              <StyledTreeItem
                nodeId="/reports/ledger"
                labelText="Ledger"
                labelIcon={FolderIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {/* {allowedPermissions.includes("view_report_ledger") ? (
            <Link to={"/reports/project-profit-loss"} style={{ textDecoration: "none" }}>
              <StyledTreeItem
                nodeId="project_profit_loss"
                labelText="Project P&L"
                labelIcon={FaProjectDiagram}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null} */}
          {allowedPermissions.includes("view_report_ledger") ? (
            <Link to={"/reports/customer-earnings"} style={{ textDecoration: "none" }}>
              <StyledTreeItem
                nodeId="/reports/customer-earnings"
                labelText="Customer Earnings"
                labelIcon={FaUserTie}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("view_report_ledger") ? (
            <Link to={"/reports/expense-report"} style={{ textDecoration: "none" }}>
              <StyledTreeItem
                nodeId="/reports/expense-report"
                labelText="Expense Report"
                labelIcon={MdOutlineReceipt}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("view_report_ledger") ? (
            <Link to={"/reports/profit-loss"} style={{ textDecoration: "none" }}>
              <StyledTreeItem
                nodeId="/reports/profit-loss"
                labelText="Profit & Loss"
                labelIcon={AiOutlineBarChart}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("view_report_ledger") ? (
            <Link to={"/reports/company-profit-loss"} style={{ textDecoration: "none" }}>
              <StyledTreeItem
                nodeId="/reports/company-profit-loss"
                labelText="Company P&L"
                labelIcon={BiBuildings}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
        </StyledTreeItem>
      ) : null}
      {/* ------------------------------------------------------REPORTS END ---------------------------------------------------- */}


      {/* -------------------------------------------DOMAIN PARAMETERS START---------------------------------------------------------------- */}

      {permissionsAccessFunction("company_getAll") ||
        permissionsAccessFunction("customer_getAll") ||
        permissionsAccessFunction("tax_getAll") ||
        permissionsAccessFunction("terms_and_condition_getAll") ||
        permissionsAccessFunction("account_getAll") ||
        permissionsAccessFunction("templetes_getAll") ||
        permissionsAccessFunction("recurring_emails_getAll") ||
        permissionsAccessFunction("designation_getAll") ||
        permissionsAccessFunction("branch_getAll") ||
        permissionsAccessFunction("deduction_getAll") ||
        permissionsAccessFunction("leaveType_getAll") ||
        permissionsAccessFunction("companyPolicy_getAll") ||
        permissionsAccessFunction("allowance_getAll") ||
        permissionsAccessFunction("currency_getAll") ? (
        <StyledTreeItem
          nodeId="/all-center-point"
          labelText="Domain Parameters"
          labelIcon={AdjustIcon}
        >
          {allowedPermissions.includes("domainParameter.company.viewAll") ? (
            <Link
              to={"/domain-parameter/all-company"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/all-company"
                labelText="Company"
                labelIcon={StoreIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.customer.viewAll") &&
            assignUserChecking == "false" ? (
            <Link
              to={"/domain-parameter/all-customer"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/all-customer"
                labelText="Customer"
                labelIcon={PeopleSharpIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.tax.viewAll") &&
            assignUserChecking == "false" ? (
            <Link
              to={"/domain-parameter/all-taxes"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/all-taxes"
                labelText="Taxes"
                labelIcon={AccountBalanceSharpIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.account.viewAll") &&
            assignUserChecking == "false" ? (
            <Link
              to={"/domain-parameter/chart-of-account"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/chart-of-account"
                labelText="Chart of Account"
                labelIcon={AccountBalanceWalletSharpIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes(
            "domainParameter.terms_and_condition.viewAll"
          ) && assignUserChecking == "false" ? (
            <Link
              to={"/domain-parameter/terms-and-conditions"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/terms-and-conditions"
                labelText="Terms & Conditions"
                labelIcon={LocationIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.templates.viewAll") &&
            assignUserChecking == "false" ? (
            <Link
              to={"/domain-parameter/all-templates"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/all-templates"
                labelText="Templates"
                labelIcon={GrTemplate}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes(
            "domainParameter.recurringEmails.viewAll"
          ) ? (
            <Link
              to={"/recuring/all-recuring-email"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/recuring/all-recuring-email"
                labelText="Recuring Email"
                labelIcon={EmailSharpIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes(
            "domainParameter.designation.viewAll"
          ) ? (
            <Link
              to={"/domain-parameter/designation"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/designation"
                labelText="Add Designation"
                labelIcon={ControlCameraIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.branch.viewAll") ? (
            <Link
              to={"/domain-parameter/branch"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/branch"
                labelText="Add Branch"
                labelIcon={DnsIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.leaveType.viewAll") ? (
            <Link
              to={"/domain-parameter/leave-type"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/leave-type"
                labelText="Add Leave Type"
                labelIcon={ExitToAppIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes(
            "domainParameter.companyPolicy.viewAll"
          ) ? (
            <Link
              to={"/domain-parameter/company-policy-create"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/company-policy-create"
                labelText="Company Policy"
                labelIcon={MdOutlinePolicy}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes("domainParameter.allowance.viewAll") ? (
            <Link
              to={"/domain-parameter/payroll-allowance"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/payroll-allowance"
                labelText="Allowance"
                labelIcon={GrMoney}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes("domainParameter.deduction.viewAll") ? (
            <Link
              to={"/domain-parameter/payroll-deduction"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/payroll-deduction"
                labelText="Deduction"
                labelIcon={FaMinus}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.currency.viewAll") ? (
            <Link
              to={"/domain-parameter/currency"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/currency"
                labelText="Currency"
                labelIcon={AiOutlineDollar}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes("domainParameter.currency.viewAll") ? (
            <Link
              to={"/domain-parameter/projects-management"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/domain-parameter/projects-management"
                labelText="Projects"
                labelIcon={GoProjectSymlink}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
        </StyledTreeItem>
      ) : null}
      {/* -------------------------------------------DOMAIN PARAMETERS End---------------------------------------------------------------- */}

      {/* --------------------------------USER MARANGEMENT START----------------------------------------------- */}
      {permissionsAccessFunction("users") ? (
        <StyledTreeItem
          nodeId="/all-center-pointwwwssss"
          labelText="User Management"
          labelIcon={AccountCircleSharpIcon}
        >
          {allowedPermissions.includes("users.getAll") ? (
            <Link
              to={"/userManagment/all-users"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="/userManagment/all-users"
                labelText="Users"
                labelIcon={AccountCircleSharpIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes("assign-permission") ? (
            <Link to={"/permissions/assign"} style={{ textDecoration: "none" }}>
              <StyledTreeItem
                nodeId="/permissions/assign"
                labelText="Assign Permission"
                labelIcon={KeySharpIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes("get-role") ? (
            <Link to={"/roles/all-roles"} style={{ textDecoration: "none" }}>
              <StyledTreeItem
                nodeId="/roles/all-roles"
                labelText="Roles"
                labelIcon={FaUserTag}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
        </StyledTreeItem>
      ) : null}

    </TreeView>
  );
};

export default TreeNav;
