import React from "react";
import Chip from "@mui/material/Chip";
import GetColumnSearchProps from "helper/searchHelper";
import { FaMapLocationDot } from "react-icons/fa6";

import { format } from "date-fns";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
// permissionUser = localStorage.getItem('LoginData');
//let permissions = JSON.parse(permissionUser)?.permissions;
//const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map((permission) => permission?.permission) : [];

//Columns tables
export const RecucringColumn = ({ params, setparams }) => {
  return [
    {
      title: "Email CC",
      dataIndex: "email_cc",
      key: "email_cc",
    },
    {
      title: "Email To",
      dataIndex: "email_to",
      key: "email_to",
    },
    {
      title: "Notification Type",
      render: (recoor) => {
        return (
          <span>
            {recoor?.notification_typeId == 1
              ? "Invoice"
              : recoor?.notification_typeId == 2
                ? "Salary"
                : recoor?.notification_typeId == 3 ? "Birthday Alert" : "-"}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span>
          {status === 1 ? (
            <Tooltip title="Active">
              <CheckCircleOutlined
                style={{ color: "green", fontSize: "24px" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Inactive">
              <CloseCircleOutlined style={{ color: "red", fontSize: "24px" }} />
            </Tooltip>
          )}
        </span>
      ),
    },
  ];
};

export const QuotationColumns = ({ params, setparams }) => {
  return [
    {
      title: "Quotation No.",
      dataIndex: "quote_num",
      width: "6%",
      key: "quote_num",
      ...GetColumnSearchProps("quote_num", params, setparams),
    },
    {
      title: "Company Name",
      dataIndex: "customer_name",
      width: "9%",
      key: "customer_name",
      ...GetColumnSearchProps("company_name", params, setparams),
    },
    {
      title: "Customer Name",
      dataIndex: "company_name",
      width: "9%",
      key: "company_name",
      ...GetColumnSearchProps("company_email", params, setparams),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      width: "7%",
      key: "currency",
      ...GetColumnSearchProps("company_email", params, setparams),
    },
    {
      title: "Date",
      dataIndex: "date",
      width: "7%",
      key: "date",
      ...GetColumnSearchProps("phone_number", params, setparams),
    },
    {
      title: "Sub Total",
      dataIndex: "sub_total",
      width: "6%",
      key: "sub_total",
      ...GetColumnSearchProps("phone_number", params, setparams),
    },
    {
      title: "Quotation Type",
      dataIndex: "quotation_type",
      width: "5%",
      key: "quotation_type",
      ...GetColumnSearchProps("phone_number", params, setparams),
    },
    {
      title: "tax Price",
      dataIndex: "tax_price",
      width: "6%",
      key: "tax_price",
      ...GetColumnSearchProps("phone_number", params, setparams),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...GetColumnSearchProps("status", params, setparams, [1, 5]),
      render: (status) => (
        <span>
          {status === 1 ? (
            <Tooltip title="Active">
              <CheckCircleOutlined
                style={{ color: "green", fontSize: "24px" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Inactive">
              <CloseCircleOutlined style={{ color: "red", fontSize: "24px" }} />
            </Tooltip>
          )}
        </span>
      ),
    },
  ];
};
export const CompanyColumns = ({ params, setparams }) => {
  return [
    {
      title: "Company Logo",
      dataIndex: "company_logo",
      key: "company_logo",
      render: (image) => (
        <div style={{ height: "50px" }}>
          <img
            src={image}
            alt="User"
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      ...GetColumnSearchProps("company_name", params, setparams),
    },
    {
      title: "Company Type",
      dataIndex: "company_type",
      key: "company_type",
      ...GetColumnSearchProps("company_type", params, setparams),
    },
    {
      title: "Company Email",
      dataIndex: "company_email",
      key: "company_email",
      ...GetColumnSearchProps("company_email", params, setparams),
    },
    {
      title: "Company Phone",
      dataIndex: "phone_number",
      key: "phone_number",
      ...GetColumnSearchProps("phone_number", params, setparams),
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
      ...GetColumnSearchProps("phone_number", params, setparams),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // ...GetColumnSearchProps('status', params, setparams, [1, 5]),
      render: (status) => (
        <span>
          {status === 1 ? (
            <Tooltip title="Active">
              <CheckCircleOutlined
                style={{ color: "green", fontSize: "22px" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Inactive">
              <CloseCircleOutlined style={{ color: "red", fontSize: "22px" }} />
            </Tooltip>
          )}
        </span>
      ),
    },
  ];
};

export const CustomerColumns = ({ params, setparams }) => {
  return [
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      ...GetColumnSearchProps("company_name", params, setparams),

    },
    {
      title: "Customer Name",
      dataIndex: "represent_name",
      key: "represent_name",
      ...GetColumnSearchProps("represent_name", params, setparams),

    },
    {
      title: "Email",
      dataIndex: "company_email",
      key: "company_email",
      ...GetColumnSearchProps("company_email", params, setparams),
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      key: "phone_number",
      ...GetColumnSearchProps("phone_number", params, setparams),
    },
    {
      title: "Address",
      dataIndex: "company_address",
      render: (text) => {
        const displayText = text && text?.trim() ? text : "No Address Available"; // Handle null, undefined, and whitespace
        return (
          <Tooltip title={displayText}>
            <FaMapLocationDot style={{ cursor: "pointer", fontSize: "24px", color: "#36454F" }} />
          </Tooltip>
        );
      },
    },
 
  ];
};



export const CategoryColumns = ({ params, setparams }) => {
  return [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
      ...GetColumnSearchProps("name", params, setparams),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ...GetColumnSearchProps("description", params, setparams),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      ...GetColumnSearchProps("company_name", params, setparams),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...GetColumnSearchProps("status", params, setparams, [1, 5]),
      render: (status) => (
        <span>
          {status === 1 ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: "24px" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red", fontSize: "24px" }} />
          )}
        </span>
      ),
    },
  ];
};
export const BrandColumns = ({ params, setparams }) => {
  return [
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "user_type",
      ...GetColumnSearchProps("company_name", params, setparams),
    },
    {
      title: "Brand Logo/ArtWork",
      dataIndex: "brand_logo",
      key: "brand_logo",
      render: (image) => (
        <img src={image} alt="User" style={{ width: "50px", height: "50px" }} />
      ),
    },
    {
      title: "Brand Email",
      dataIndex: "brand_email",
      key: "brand_email",
    },
    {
      title: "Brand Country",
      dataIndex: "brand_country",
      key: "brand_country",
    },
    {
      title: "Brand City",
      dataIndex: "brand_city",
      key: "brand_city",
    },
    {
      title: "Brand Address",
      dataIndex: "brand_address",
      key: "brand_address",
    },

    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
    },
    {
      title: "Brand Phone",
      dataIndex: "brand_phone",
      key: "brand_phone",
    },
    {
      title: "Brand Url",
      dataIndex: "brand_url",
      key: "brand_url",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span>
          {status === 1 ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: "24px" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red", fontSize: "24px" }} />
          )}
        </span>
      ),
    },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   render: (text, record) => (
    //     <span>
    //       {allowedPermissions.includes('edit-brand') ? <Button
    //          type="primary" className="mx-2"
    //          variant="contained"

    //         onClick={() => handleEdit(record.id)}

    //       >
    //         Edit
    //       </Button> : null}
    //       {allowedPermissions.includes('deactivate-brand') ? <Popconfirm
    //         title={`Are you sure you want to ${record.status === 1 ? 'Deactivate' : 'Activate'
    //           } this user?`}
    //         onConfirm={() => handleUpdateStatus(record.id, record.status === 1 ? 0 : 1)}
    //       >
    //           <Button variant="contained" color={record.status === 1 ? 'error' : 'success'}>
    //                     {record.status === 1 ? 'Deactivate' : 'Activate'}
    //                     </Button>
    //       </Popconfirm> : null}
    //     </span>
    //   ),

    // },
  ];
};
export const BatchColumns = ({ params, setparams }) => {
  return [
    {
      title: "Tax",
      dataIndex: "tax_name",
      key: "tax_name",
    },

    {
      title: "Value",
      dataIndex: "tax_value",
      key: "tax_value",
    },

    {
      title: "Type",
      dataIndex: "tax_type",
      key: "tax_type",
      render: (tax_type) => (
        <span>{tax_type === "1" ? "Percentage" : "Fixed"}</span>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span>
          {status === 1 ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: "24px" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red", fontSize: "24px" }} />
          )}
        </span>
      ),
    },
  ];
};

export const ChallanColumns = ({ params, setparams }) => {
  return [
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },

    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Delivery Refrence",
      dataIndex: "del_number",
      key: "del_number",
    },

    {
      title: "Invoice Refrence",
      dataIndex: "invoice_number",
      key: "invoice_number",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Created User",
      dataIndex: "createdUser",
      key: "createdUser",
    },
  ];
};

export const ChartOfAccountColumns = ({ params, setparams }) => {
  return [
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },

    {
      title: "Account Type",
      dataIndex: "type_name",
      key: "type_name",
    },
    {
      title: "Account Address",
      dataIndex: "to_increase",
      key: "to_increase",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (status) => (
        <span>
          {status === 1 ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red", fontSize: "22px" }} />
          )}
        </span>
      ),
    },
  ];
};

export const TermsAndConditionsColumns = ({ params, setparams }) => {
  return [
    {
      title: "Term Name",
      dataIndex: "term_name",
      key: "term_name",
    },

    {
      title: "Type Name",
      dataIndex: "type_name",
      key: "type_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (status) => (
        <span>
          {status === 1 ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red", fontSize: "22px" }} />
          )}
        </span>
      ),
    },
  ];
};

export const TemplateColumn = ({ params, setparams }) => {
  return [
    {
      title: "Template Name",
      dataIndex: "templete_name",
      key: "templete_name",
    },

    {
      title: "Template Code",
      dataIndex: "type_name",
      key: "type_name",
    },
  ];
};
export const UsersColumns = ({ params, setparams }) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "User Type",
      dataIndex: "role_name",
      key: "role_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    // {
    //   title: 'company_id',
    //   dataIndex: 'company_id',
    //   key: 'company_id',
    //   ...GetColumnSearchProps('company_id', params, setparams)
    // },
    // {
    //   title: 'Created By',
    //   dataIndex: 'created_by',
    //   key: 'created_by',
    //   ...GetColumnSearchProps('created_by', params, setparams)
    // },

    {
      title: "Date Added",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => (
        <span>{format(new Date(created_at), "dd/MM/yyyy")}</span>
      ),
    },
    // {
    //   title: 'Date Modified',
    //   dataIndex: 'created_at',
    //   key: 'created_at'
    // },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span>
          {status === 1 ? (
            <Tooltip title="Active">
              <CheckCircleOutlined
                style={{ color: "green", fontSize: "22px" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Inactive">
              <CloseCircleOutlined style={{ color: "red", fontSize: "22px" }} />
            </Tooltip>
          )}
        </span>
      ),
    },
  ];
};
export const RandomColumns = ({ params, setparams }) => {
  return [
    {
      title: "QR Code",
      dataIndex: "svg_code",
      key: "svg_code",
      render: (iconUrl) => (
        <div dangerouslySetInnerHTML={{ __html: iconUrl }} />
      ),
    },
    {
      title: "Random Number",
      dataIndex: "random_code",
      key: "random_code",
      ...GetColumnSearchProps("random_code", params, setparams),
    },
    {
      title: "Serial Number",
      dataIndex: "pair_value",
      key: "pair_value",
      ...GetColumnSearchProps("pair_value", params, setparams),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      ...GetColumnSearchProps("company", params, setparams),
    },

    {
      title: "Verfication Count",
      dataIndex: "verified_count",
      key: "verified_count",
    },
    {
      title: "Random Digits",
      dataIndex: "digits",
      key: "digits",
    },

    {
      title: "Activation Status",
      dataIndex: "activation_status",
      key: "activation_status",
      render: (status) => (
        <span>
          {status === 1 ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: "24px" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red", fontSize: "24px" }} />
          )}
        </span>
      ),
    },
    {
      title: "Batch ID",
      dataIndex: "batch_id",
      key: "batch_id",
      ...GetColumnSearchProps("batch_id", params, setparams),
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        const date = new Date(created_at);
        const formattedDate = format(date, "dd/MM/yyyy");
        return <span>{formattedDate}</span>;
      },
    },
  ];
};
export const ProductsColumns = ({ params, setparams }) => {
  return [
    {
      title: "Created By",
      dataIndex: "CreatedUser",
      key: "CreatedUser",
      ...GetColumnSearchProps("CreatedUser", params, setparams),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <img src={image} alt="User" style={{ width: "50px", height: "50px" }} />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...GetColumnSearchProps("name", params, setparams),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ...GetColumnSearchProps("description", params, setparams),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      ...GetColumnSearchProps("company_name", params, setparams),
    },

    {
      title: "Category Name",
      dataIndex: "category_name",
      key: "category_name",
      ...GetColumnSearchProps("category_name", params, setparams),
    },
    {
      title: "Sub Category Name",
      dataIndex: "sub_cate",
      key: "sub_cate",
      ...GetColumnSearchProps("sub_cate", params, setparams),
    },

    {
      title: "Date Added",
      dataIndex: "date_added",
      key: "date_added",
      render: (date_added) => {
        const date = new Date(date_added);
        const formattedDate = format(date, "dd/MM/yyyy");
        return <span>{formattedDate}</span>;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span>
          {status === 1 ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: "24px" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red", fontSize: "24px" }} />
          )}
        </span>
      ),
    },
  ];
};

export const branchesColusmns = ({ params, setparams }) => {
  return [
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      key: "branch_name",
    },
    {
      title: "IP Address",
      dataIndex: "ip_address",
      key: "ip_address",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        const date = new Date(created_at);
        const formattedDate = format(date, "dd/MM/yyyy");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (status) => (
        <span>
          {status === 1 ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red", fontSize: "22px" }} />
          )}
        </span>
      ),
    },
  ];
};

export const desginationsColusmns = ({ params, setparams }) => {
  return [
    {
      title: "Designation Name",
      dataIndex: "desg_name",
      key: "desg_name",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        const date = new Date(created_at);
        const formattedDate = format(date, "dd/MM/yyyy");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (status) => (
        <span>
          {status === 1 ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red", fontSize: "22px" }} />
          )}
        </span>
      ),
    },
  ];
};

export const leaveTypeeColusmns = ({ params, setparams }) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Deduction",
      dataIndex: "deduction_is",
      key: "deduction_is",
      render: (text) => (text === 5 ? "No" : text === 1 ? "Yes" : "Unknown"),
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        const date = new Date(created_at);
        const formattedDate = format(date, "dd/MM/yyyy");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (status) => (
        <span>
          {status === 1 ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red", fontSize: "22px" }} />
          )}
        </span>
      ),
    },
  ];
};

function formatTime(timeStr) {
  const [hours, minutes, seconds] = timeStr.split(":").map(Number);

  const date = new Date();
  date.setHours(hours, minutes, seconds);
  let formattedHours = date.getHours() % 12 || 12;
  const ampm = date.getHours() >= 12 ? "PM" : "AM";
  const formattedMinutes = date.getMinutes().toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export const AttendenceColumnss = () => {
  return [
    {
      title: "Machine Id",
      dataIndex: "employee_id",
      key: "employee_id",
    },
    {
      title: "Employee Name",
      dataIndex: "emp",
      key: "emp",
      render: (employee_name) => {
        return <span>{employee_name?.employee_name}</span>;
      },
    },
    {
      title: "Attendence Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Check In",
      dataIndex: "check_in",
      key: "check_in",
      render: (created_at) => {
        const formattedDate = formatTime(created_at);
        return <span>{formattedDate}</span>;
      },
    },

    {
      title: "Check Out",
      dataIndex: "check_out",
      key: "check_out",
      render: (created_at) => {
        const formattedDate = formatTime(created_at);
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Early Leaving",
      dataIndex: "early_leaving",
      key: "early_leaving",
    },
    {
      title: "Lates",
      dataIndex: "late",
      key: "late",
    },
    {
      title: "Over Time",
      dataIndex: "overtime",
      key: "overtime",
    },
    {
      title: "Total Hours",
      dataIndex: "total_hours",
      key: "total_hours",
    },
  ];
};
