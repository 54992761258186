export const FETCH_LOGIN_DATA = "FETCH_LOGIN_DATA";
export const FETCH_COMPANY = "FETCH_COMPANY";
export const CUSTOMER_DATA = "CUSTOMER_DATA";

export const FETCH_CURRENCY = "FETCH_CURRENCY";
export const FETCH_DASHBOARD = "FETCH_DASHBOARD";
export const REGISTER_COMPANY = "REGISTER_COMPANY";
export const REGISTER_TAXES = "REGISTER_TAXES";
export const FETCH_TAXES = "FETCH_TAXES";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const NUMBER_FIELD = "NUMBER_FIELD";
export const STORE_QUOTATION = "STORE_QUOTATION";
export const UPDATE_QUOTATION = "STORE_QUOTATION";
export const VIEW_QUOTATION = "VIEW_QUOTATION";
export const VIEW_ROLES = "VIEW_ROLES";
export const GETALL_PERMISSION = "GETALL_PERMISSION";
export const RECURING_ALL_DATA = "RECURING_ALL_DATA";
export const RECURING_FETCH = "RECURING_FETCH";
export const ALL_GET_REPORTS = "ALL_GET_REPORTS";
export const ALL_PROJECT_PROFIT_AND_LOSS = "ALL_PROJECT_PROFIT_AND_LOSS";
export const ALL_PAYSLIP_PDF_DOWNLOAD = "ALL_PAYSLIP_PDF_DOWNLOAD";
export const ALL_CUSTOMER_EARNING_REPORTS = "ALL_CUSTOMER_EARNING_REPORTS";
export const ALL_EXPENSE_SUMMARY_REPORTS = "ALL_EXPENSE_SUMMARY_REPORTS";
export const ALL_OVERALL_PROFIT_LOSS = "ALL_OVERALL_PROFIT_LOSS";
export const ALL_COMPANY_PROFIT_LOSS = "ALL_COMPANY_PROFIT_LOSS";

export const CUSTOMER_EDIT = "CUSTOMER_EDIT";
export const CUSTOMER_DELETE = "CUSTOMER_DELETE";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const CREATE_ROLE = "CREATE_ROLE";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
// ___________________________DASHBOARD_________________________
export const INVOICE_REPORTS = "INVOICE_REPORTS";
export const QUOTATIONS_REPORTS = "QUOTATIONS_REPORTS";
export const CHALLANS_REPORTS = "CHALLANS_REPORTS";
export const TOP_RATED_CUSTOMERS = "TOP_RATED_CUSTOMERS";
// ___________________________DASHBOARD_________________________
// __________________________________________________________________REPORTS_________________________________________________

export const LEDGER_REPORTS = "LEDGER_REPORTS";
export const INVOICE_ALL_EXPORT = "INVOICE_ALL_EXPORT";

export const VOUCHER_ALL_EXPORT = "VOUCHER_ALL_EXPORT";

export const QUOTATIONS_ALL_EXPORT = "QUOTATIONS_ALL_EXPORT";

export const CHALLAN_ALL_EXPORT = "CHALLAN_ALL_EXPORT";

export const QUOTATIONS_ALL_DELETE = "QUOTATIONS_ALL_DELETE";

export const INVOICE_ALL_DELETE = "INVOICE_ALL_DELETE";

export const CHALLAN_ALL_DELETE = "CHALLAN_ALL_DELETE";

export const VOUCHER_ALL_DELETE = "VOUCHER_ALL_DELETE";
export const LEAVE_MANAGEMENT = "LEAVE_MANAGEMENT";
export const LEAVE_MANAGEMENT_CREATE = "LEAVE_MANAGEMENT_CREATE";

export const GENERATE_SALARY_CREATE = "GENERATE_SALARY_CREATE";
export const GENERATE_SALARY_UPDATE = "GENERATE_SALARY_UPDATE";


export const LEAVE_MANAGEMENT_GET = "LEAVE_MANAGEMENT_GET";
export const ALL_EMPLOYEE_MANAGEMENT = "ALL_EMPLOYEE_MANAGEMENT";
export const ALL_EMPLOYEE_MANAGEMENT_FILTER = "ALL_EMPLOYEE_MANAGEMENT_FILTER";
export const CREATE_EMPLOYEE_MANAGEMENT = "CREATE_EMPLOYEE_MANAGEMENT";
export const UPDATE_EMPLOYEE_MANAGEMENT = "UPDATE_EMPLOYEE_MANAGEMENT";
export const GET_COMPANY_POLICY = "GET_COMPANY_POLICY";
export const CREATE_COMPANY_POLICY = "CREATE_COMPANY_POLICY";
export const LEAVE_MANAGEMENT_UPDATE = "LEAVE_MANAGEMENT_UPDATE";
export const LEAVE_MANAGEMENT_DELETE = "LEAVE_MANAGEMENT_DELETE";
export const CREATE_LEAVE_MANAGEMENT = "CREATE_LEAVE_MANAGEMENT";
export const LEAVES_REQUEST_APPROVED = "LEAVES_REQUEST_APPROVED";
export const GET_ALL_LOANS = "GET_ALL_LOANS";
export const GET_ALL_LOANS_HISTORY = "GET_ALL_LOANS_HISTORY";
export const GET_ALL_PAYSLIP = "GET_ALL_PAYSLIP";
export const EXPORT_ALL_PAYSLIP = "EXPORT_ALL_PAYSLIP";
export const GET_ALL_PAYSALARY = "GET_ALL_PAYSALARY";
export const GET_ALL_PAYSALARY_FETCH = "GET_ALL_PAYSALARY_FETCH";
export const CREATE_ALL_LOANS = "CREATE_ALL_LOANS";
export const LEAVES_REQUEST_CANCEL = "LEAVES_REQUEST_CANCEL";
export const LEAVES_REQUEST_REJECT = "LEAVES_REQUEST_REJECT";
export const ROSTER_CREATE = "ROSTER_CREATE";
export const ALL_LOAN_TYPE = "ALL_LOAN_TYPE";
export const EDIT_ALL_LOANS = "EDIT_ALL_LOANS";
export const CREATE_RETURN_LOANS = "CREATE_RETURN_LOANS";
export const GET_ALL_ATTENDANCE = "GET_ALL_ATTENDANCE";
export const EXPORT_GET_ATTENDANCE = "EXPORT_GET_ATTENDANCE";
export const GET_ALL_PAYROLL = "GET_ALL_PAYROLL";
export const CREATE_EMP_SALARY = "CREATE_EMP_SALARY";
export const GET_EMPLOYEE_SALARY = "GET_EMPLOYEE_SALARY";

export const CREATE_ALL_ATTENDANCE = "CREATE_ALL_ATTENDANCE";
export const EXTRACT_ALL_ATTENDANCE = "EXTRACT_ALL_ATTENDANCE";
export const ALL_EMPLOYEE_ALLOWANCE = "ALL_EMPLOYEE_ALLOWANCE";
export const ALL_PAYROLL_ALLOWANCE = "ALL_PAYROLL_ALLOWANCE";
export const UPDATE_EMPLOYEE_ALLOWANCE = "UPDATE_EMPLOYEE_ALLOWANCE";

export const UPDATE_SATURATION_DEDUCTION = "UPDATE_SATURATION_DEDUCTION";

export const UPDATE_OTHER_PAYMENTS = "UPDATE_OTHER_PAYMENTS";

export const GET_ALL_PAYROLL_ALLOWANCE = "GET_ALL_PAYROLL_ALLOWANCE";
export const CREATE_ALL_PAYROLL_ALLOWANCE = "CREATE_ALL_PAYROLL_ALLOWANCE";
export const UPDATE_ALL_PAYROLL_ALLOWANCE = "UPDATE_ALL_PAYROLL_ALLOWANCE";

export const DELETE_ALL_PAYROLL_ALLOWANCE = "DELETE_ALL_PAYROLL_ALLOWANCE";


export const GET_ALL_DEDUCTION = "GET_ALL_DEDUCTION";
export const GET_ALL_CURRENCY = "GET_ALL_CURRENCY";

export const CREATE_ALL_CURRENCY = "CREATE_ALL_CURRENCY";
export const UPDATE_ALL_CURRENCY = "UPDATE_ALL_CURRENCY";
export const DELETE_ALL_CURRENCY = "DELETE_ALL_CURRENCY";








// __________________________________________________________REPORTS__________________________________________________
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const UPDATE_TAXES = "UPDATE_TAXES";
export const DELETE_TAXES = "DELETE_TAXES";
export const CUSTOMER_REGISTER = "CUSTOMER_REGISTER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const CHART_ACCOUNT = "CHART_ACCOUNT";
export const REGISTER_ACCOUNT = "REGISTER_ACCOUNT";
export const INVOICE_REGISTER = "INVOICE_REGISTER";
export const CHALLAN_SEND = "CHALLAN_SEND";
export const MAIN_INVOICE = "MAIN_INVOICE";
export const MAIN_INVOICE_UPDATE = "MAIN_INVOICE_UPDATE";
export const QUOTATION_ALLGET = "QUOTATION_ALLGET";
export const INVOICE_ALLVIEW = "INVOICE_ALLVIEW";
// export const MAIN_DASHBOARD = "MAIN_DASHBOARD"
export const ALLUSERS = "ALLUSERS";
export const ALLUSERSDETAILS = "ALLUSERSDETAILS";
export const ALLCREATEUSERS = "ALLCREATEUSERS";
export const ALL_USER_DELETE = "ALL_USER_DELETE";

export const CHALLAN_ALLVIEW = "CHALLAN_ALLVIEW";
export const CHALLAN_DETAILS = "CHALLAN_DETAILS";
export const INVOICE_DETAILS = "INVOICE_DETAILS";
export const QUOTATION_DETAILS = "QUOTATION_DETAILS";
export const ALLQUOTATION_FILTER = "ALLQUOTATION_FILTER";
export const PAYMENT_VOUCHER = "PAYMENT_VOUCHER";
export const PAYMENT_VOUCHER_UPDATE = "PAYMENT_VOUCHER_UPDATE";
export const EXPENSE_VOUCHER = "EXPENSE_VOUCHER";
export const TEMPLATE_GET = "TEMPLATE_GET";
export const TEMPLATE_FETCH = "TEMPLATE_FETCH";
export const TEMPLATE_UPDATE = "TEMPLATE_UPDATE";
export const TEMPLATE_DELETE = "TEMPLATE_DELETE";
export const NEWTEMAE_DETAILS = "NEWTEMAE_DETAILS";
export const INVOICE_UPDATE = "INVOICE_UPDATE";
export const CHALLAN_UPDATE = "CHALLAN_UPDATE";
export const PAYMENTVOUCHER_VIEW = "PAYMENTVOUCHER_VIEW";
export const TERMS_SENDFETCH = "TERMS_SENDFETCH";
export const TERMS_ALLGET = "TERMS_ALLGET";
export const TERMS_ALLACTIVE = "TERMS_ALLACTIVE";
export const TERMS_EDIT = "TERMS_EDIT";
export const TERMS_DELETE = "TERMS_DELETE";
export const TERMS_DETAILS = "TERMS_DETAILS";
export const ASSIGN_PERMISSIONS = "ASSIGN_PERMISSIONS";
export const ROLE_PERMISSIONS = "ROLE_PERMISSIONS";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const PAYMENT_VOUCHER_DATAILS = "PAYMENT_VOUCHER_DATAILS";
export const UPDATE_RECURING_EMAIL = "UPDATE_RECURING_EMAIL";

export const ALL_PDF_UNLOCK = "ALL_PDF_UNLOCK";
export const INCREPTION_PASS_DATA = "INCREPTION_PASS_DATA";

export const CREATE_DESIGNATION_CRS = "CREATE_DESIGNATION_CRS";
export const CREATE_LEAVE_TYPE_IS = "CREATE_LEAVE_TYPE_IS";
export const CREATE_BRANCHES_BNS = "CREATE_BRANCHES_BNS";

export const BRANCHES_VIEW_DATAS = "BRANCHES_VIEW_DATAS";


export const ALL_VIEW_VENDORS = "ALL_VIEW_VENDORS";
export const CREATE_VIEW_VENDORS = "CREATE_VIEW_VENDORS";

export const DESIGNATIONSS_GET_DATA_VIEW = "DESIGNATIONSS_GET_DATA_VIEW";
// export const GET_LEAVE_MANAGEMENT = "GET_LEAVE_MANAGEMENT";

export const LEAVETYPE_GT_VIEW = "LEAVETYPE_GT_VIEW";
export const ACTIVE_DEACTIVE_BRANCHE = "ACTIVE_DEACTIVE_BRANCHE";
export const ACTIVE_DEACTIVE_DSGNTS = "ACTIVE_DEACTIVE_DSGNTS";
export const BRANCH_EDIT_UPDATE = "BRANCH_EDIT_UPDATE";
export const DESIGNATIONS_EDIT_UPDATE = "DESIGNATIONS_EDIT_UPDATE";

export const LEAVE_TYPE_EDIT = "LEAVE_TYPE_EDIT";
export const LEAVE_TYPE_STATUS_UPDATE = "LEAVE_TYPE_STATUS_UPDATE";
export const DELETE_EMP_EXPENSE_SALARY = "DELETE_EMP_EXPENSE_SALARY";
export const CREATE_SALARY_FETCH = "CREATE_SALARY_FETCH";

export const CREATE_AMOUNT_DEDUCTION = "CREATE_AMOUNT_DEDUCTION";

export const UPDATE_AMOUNT_DEDUCTION = "UPDATE_AMOUNT_DEDUCTION";
export const DELETE_AMOUNT_DEDUCTION = "DELETE_AMOUNT_DEDUCTION";
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const CREATE_ALL_PROJECTS = "CREATE_ALL_PROJECTS";

export const UPDATE_ALL_PROJECTS = "UPDATE_ALL_PROJECTS";

export const UPDATE_STATUS_PROJECTS = "UPDATE_STATUS_PROJECTS";
export const UPDATE_STATUS_EMPLOYEE = "UPDATE_STATUS_EMPLOYEE";

export const ALL_EMPLOYEE_EXCEL = "ALL_EMPLOYEE_EXCEL";

export const UPLOAD_EMPLOYEE_CSV = "ALL_EMPLOYEE_EXCEL";


