import MainCard from "components/MainCard";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { downlaodpdfurl } from "store/constant/Endpoints";
import AddCompanyModal from "components/modals/addcompnaymodal";
import ThemeInput from "components/inputs/ThemeInput";
import { useEffect, useRef, useState } from "react";
import CreateProjectModal from "../../components/addprojectmodal";
import { Editor } from "react-draft-wysiwyg";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import ReactQuill from "react-quill";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MultiSelectInput from "../../components/inputs/multiselect";
import "react-quill/dist/quill.snow.css";
import { MdOutlineSubtitles } from "react-icons/md";
import Chip from '@mui/material/Chip';
import { PlusOutlined, CloseOutlined, ToolOutlined } from '@ant-design/icons';
import { FormOutlined } from '@ant-design/icons';
import { UserOutlined } from "@ant-design/icons";
import { Tooltip } from 'antd';
import TitleIcon from '@mui/icons-material/Title';
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Spin, Table, Space, Input, Modal, Upload, Divider, Card, Timeline, Tag, Typography, Empty } from "antd";
import { dispatch } from "store/index";
import {
  fetchQuoatationGetAll,
  fetchAllVwwTrmsoitins,
  fetchNumberField,
  fetchCustomer,
  fetchCompanyDataa,
  fetchStoreQuotation,
  GetFetchProjects,
  fetchTaxesComp,
  fetchCurrency,
  fetchViewQuotation,
  updateStoreQuotation,
  fetchAllTrmActive,
} from "store/action/index";
import { DeleteOutlined } from "@ant-design/icons";
import { Select as SelectAnt } from "antd";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SearchTextSelect from "components/selects/SearchTextSelect";
import { log } from "../../../node_modules/util/util";
import AddIcon from "@mui/icons-material/Add";
import { use } from "react";
import { set } from "store";

// ==============================|| Create / Edit Company - SICPA ||============================== //
const { Text } = Typography;


const CreateQuotation = ({
  quotationsssactive,
  customerData,
  companyData,
  allProjData,
  taxes,
  numberfield,
  currencyData,
  viewQuotionData,
}) => {

  const getDateAfterDays = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date.toISOString().split('T')[0];
  };
  const location = useLocation();
  const { TextArea } = Input;
  const [modalVisible, setModalVisible] = useState(false);
  const [discount_dis_cond, setDiscount_DIs_COnd] = useState(false);
  const [open, setOpen] = useState(false);
  const [pdfType, setPdfType] = useState("");
  const [increaseAmount, setIncreaseAmount] = useState(0);
  const [uploadedData, setUploadedData] = useState([]);
  const [TermDecccc, SetTermCondiDescri] = useState(
    location.state !== null ? location.state?.terms : ""
  );
  const [is_lock, setIsLock] = useState("1");
  const [note, setNotee] = useState(
    location.state !== null ? location.state?.note : ""
  );
  const [company_id, setCompanyId] = useState(
    location.state !== null ? location.state?.company_id : ""
  );
  const [customer_id, setCustomerId] = useState(location.state !== null ? location.state?.customer_name : "");
  const [customerIddd, setFilterCustomerIdd] = useState(location.state !== null ? location.state?.customer_id : "");
  const [termconditonGet, setTermCOndit] = useState();
  const [loading, setLoading] = useState(false);
  const [currency, setcurrency] = useState(
    location.state !== null ? location.state?.currency : ""
  );
  const [Modalcompany, setModalCompany] = useState(
    location.state !== null ? location.state?.customer_name : ""
  );
  const [AdditionalPrice, setAdditionalPrice] = useState(
    location.state !== null ? location.state?.customer_name : ""
  );
  const [payment_term, setpayment_term] = useState(
    location.state !== null ? location.state?.payment_term : ""
  );
  const [modalOpen, setModalOpen] = useState(false);

  const [date, setdate] = useState(
    location.state !== null
      ? location.state?.date
      : new Date().toISOString().split("T")[0]
  );
  const [expDate, setExpDate] = useState(getDateAfterDays(10));
  const [quotation_type, setquotation_type] = useState(
    location.state !== null ? location.state?.quotation_type : ""
  );
  const [discount, setdiscount] = useState(
    location.state !== null ? location.state?.discount : 0
  );
  const [discount_type, setdiscountType] = useState("");
  const [conditionOverAll, setCondtionOvelAlling] = useState([]);
  const [number, setNumber_Field] = useState("");
  const [csvCOndition, setCSVCOnditon] = useState(false);
  const [OverAllTax, setOverAllTax] = useState([
    { tax_id: "", tax_value: "", tax_type: "" },
  ]);
  const [tableData, setTableData] = useState([
    {
      columnsdata: [
        {
          s_no: "1",
          description: "",
          qty: 0,
          unit_type: "",
          unit: "",
          unit_price: 0,
          total_price: 0,
          title_name: ""
        },
      ],
      sub_total: "",
      taxes: [{ tax_id: "", tax_value: "", tax_type: "" }],
      services: [{ service_name: "", service_amount: "" }],
      total: "",
      table_title: "",
      discount: "",
      discount_type: "",
      sub_title: "",
    },
  ]);
  const [assignProjects, setAssignProjects] = useState('');

  useEffect(() => {
    console.log("Projectsgg", location?.state);
  }, []);
  const modules = {
    toolbar: [
      ['bold'], // Only bold
      [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Numbered and bullet lists
      ['clean'] // Remove formatting
    ],
  };

  const formats = [
    'bold', // Only bold
    'list', 'bullet' // Numbered and bullet lists
  ];

  useEffect(() => {
    if (termconditonGet) {
      SetTermCondiDescri(termconditonGet);
    }
  }, [termconditonGet]);

  useEffect(() => {
    console.log("quotationIDD", viewQuotionData)
  }, [viewQuotionData])

  useEffect(() => {
    dispatch(fetchCustomer());
    dispatch(fetchCompanyDataa());
    dispatch(fetchAllVwwTrmsoitins());
    dispatch(fetchQuoatationGetAll());
    dispatch(fetchTaxesComp());
    dispatch(GetFetchProjects({ params: { paginate: "all" } }));
    dispatch(fetchCurrency());
  }, []);

  const formatNumber = (value) => {
    if (!value && value !== 0) return "";
    return new Intl.NumberFormat('en-US').format(value);
  };
  const parseFormattedNumber = (value) => {
    return value ? value.replace(/,/g, '') : '';
  };

  const handleImageUpload = async (file, onSuccess, onError) => {
    try {
      // Check for valid file extension
      const validExtensions = /\.(csv|xls|xlsx)$/i;
      if (!validExtensions.test(file.name)) {
        throw new Error("Invalid file type. Only Excel files are allowed.");
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const allSheetsData = workbook.SheetNames.map((sheetName) => {
          const sheet = workbook.Sheets[sheetName];
          let excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          if (excelData.length > 0) {
            excelData = excelData.slice(1); // Skip header
          }
          setCSVCOnditon(true)
          const sheetData = [];
          let currentHeader = "";

          excelData?.forEach((row, index) => {
            if (row.length == 1 && row[0]) {
              currentHeader = row[0];
            } else if (row.length > 1) {
              sheetData.push({
                s_no: (sheetData.length + 1).toString(), // Ensure consistent numbering
                description: row[0] || "",
                qty: row[1] || 0,
                unit_type: row[2] || "",
                unit_price: row[3] || 0,
                total_price: row[1] * row[3] || 0,
                title_name: currentHeader, // Use the current header before resetting it
              });
              currentHeader = "";
            }
          });

          return {
            sheetName,
            columnsdata: sheetData,
            sub_total: sheetData
              ?.reduce((acc, row) => acc + row.total_price, 0)
              .toFixed(2),
            taxes: [{ tax_id: "", tax_value: "", tax_type: "" }],
            services: [{ service_name: "", service_amount: "" }],
            total: "",
            discount: "",
            discount_type: "",
            table_title: sheetName,
            sub_title: "",

          };
        });

        // Temporarily store the data until the user submits
        setUploadedData(allSheetsData);
        onSuccess();
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      onError(error);
      console.error("Upload failed:", error.message);
    }
  };


  const closeModal = () => {
    setModalVisible(false);
  };

  const handleFieldChange = (tableIndex, fieldIndex, field, value) => {
    const updatedTableData = [...tableData];

    if (
      updatedTableData[tableIndex] &&
      Array.isArray(updatedTableData[tableIndex].columnsdata)
    ) {
      const updatedFields = [...updatedTableData[tableIndex].columnsdata];
      const updatedSerFields = [...updatedTableData[tableIndex].services];

      if (updatedFields[fieldIndex] !== undefined ||
        updatedSerFields[fieldIndex] !== undefined) {
        // Update the field value
        updatedFields[fieldIndex][field] = value;

        // Calculate total price for the current row
        const qty = parseFloat(updatedFields[fieldIndex].qty) || 0;
        const unitPrice = parseFloat(updatedFields[fieldIndex].unit_price) || 0;
        updatedFields[fieldIndex].total_price = (qty * unitPrice).toFixed(2);

        // Update columnsdata
        updatedTableData[tableIndex].columnsdata = updatedFields;

        // Calculate sub_total
        const subTotal = updatedFields
          ?.reduce((acc, row) => acc + parseFloat(row.total_price || 0), 0)
          .toFixed(2);
        updatedTableData[tableIndex].sub_total = subTotal;
        const discount = parseFloat(updatedTableData[tableIndex].discount) || 0;
        const discountType = updatedTableData[tableIndex].discount_type;

        let newSubTotal = parseFloat(subTotal);

        if (discountType == "2") {
          newSubTotal = Math.max(newSubTotal - discount, 0);
        } else if (discountType == "1") {
          const percentageDiscount = (newSubTotal * discount) / 100;
          newSubTotal = Math.max(newSubTotal - percentageDiscount, 0);
        }

        updatedTableData[tableIndex].sub_total = newSubTotal.toFixed(2);

        let total = newSubTotal;
        updatedSerFields.forEach((service) => {
          const serv_amt = parseFloat(service.service_amount) || 0;
          total += serv_amt;
        });

        // Calculate Total
        // let total = parseFloat(subTotal);

        const taxes = updatedTableData[tableIndex].taxes;
        if (taxes && taxes.length > 0) {
          taxes.forEach((tax) => {
            const taxValue = parseFloat(tax.tax_value) || 0;

            if (tax.tax_type == "1") {
              // Percentage-based tax
              total += (total * taxValue) / 100;
            } else if (tax.tax_type == "2") {
              // Fixed-value tax
              total += taxValue;
            }
          });
        }

        // Update total
        updatedTableData[tableIndex].total = total.toFixed(2);

        // Update state
        setTableData(updatedTableData);
      } else {
        console.error("Field index out of bounds", { tableIndex, fieldIndex });
      }
    } else {
      console.error(
        "Table index out of bounds or columnsdata is not an array",
        { tableIndex }
      );
    }
  };

  const handleFieldSERvicesChange = (tableIndex, fieldIndex, field, value) => {
    const updatedTableData = [...tableData];

    if (
      updatedTableData[tableIndex] &&
      Array.isArray(updatedTableData[tableIndex].services)
    ) {
      const updatedFields = [...updatedTableData[tableIndex].services];
      const updatedTaxFields = [...updatedTableData[tableIndex].taxes];

      if (
        updatedFields[fieldIndex] !== undefined ||
        updatedTaxFields[fieldIndex] !== undefined
      ) {
        updatedFields[fieldIndex][field] = value;
        updatedTableData[tableIndex].services = updatedFields;

        let subTotal = parseFloat(updatedTableData[tableIndex].sub_total) || 0;

        let total = subTotal;

        updatedFields.forEach((service) => {
          const serv_amt = parseFloat(service.service_amount) || 0;
          total += serv_amt;
        });

        updatedTaxFields.forEach((tax) => {
          const taxValue = parseFloat(tax.tax_value) || 0;
          const taxType = parseInt(tax.tax_type, 10);

          if (taxType == 1) {
            // Tax type 1: percentage
            total += (subTotal * taxValue) / 100;
          } else if (taxType == 2) {
            // Tax type 2: flat amount
            total += taxValue;
          }
        });

        updatedTableData[tableIndex].total = total.toFixed(2);
        setTableData(updatedTableData);
      } else {
        console.error("Field index out of bounds", { tableIndex, fieldIndex });
      }
    } else {
      console.error("Table index out of bounds or services is not an array", {
        tableIndex,
      });
    }
  };

  const handleFieldTaxChange = (tableIndex, fieldIndex, selectedTax) => {
    const updatedTableData = [...tableData];
    if (
      updatedTableData[tableIndex] &&
      Array.isArray(updatedTableData[tableIndex].taxes)
    ) {
      const updatedFields = [...updatedTableData[tableIndex].taxes];
      const updatedSerFields = [...updatedTableData[tableIndex].services];

      if (updatedFields[fieldIndex] !== undefined || updatedSerFields[fieldIndex] !== undefined) {
        // Update the tax object directly
        const modifiedTax = { ...selectedTax };
        if ('id' in modifiedTax) {
          modifiedTax.tax_id = modifiedTax.id;  // Copy id value to tax_id
          delete modifiedTax.id;                // Remove the old id key
        }

        updatedFields[fieldIndex] = {
          ...updatedFields[fieldIndex],
          ...modifiedTax,
        };

        updatedTableData[tableIndex].taxes = updatedFields;

        // Recalculate total
        const subTotal =
          parseFloat(updatedTableData[tableIndex].sub_total) || 0;
        let total = subTotal;

        updatedSerFields.forEach((service) => {
          const serv_amt = parseFloat(service.service_amount) || 0;
          total += serv_amt;
        });

        updatedFields.forEach((tax) => {
          const taxValue = parseFloat(tax.tax_value) || 0;
          const taxType = parseInt(tax.tax_type, 10);
          if (taxType == 1) {
            // Tax type 1: percentage
            total += (subTotal * taxValue) / 100;
          } else if (taxType == 2) {
            // Tax type 2: flat amount
            total += taxValue;
          }
        });

        updatedTableData[tableIndex].total = total.toFixed(2);
        setTableData(updatedTableData);
      } else {
        console.error("Field index out of bounds", { tableIndex, fieldIndex });
      }
    } else {
      console.error("Table index out of bounds or taxes is not an array", {
        tableIndex,
      });
    }
  };

  const handleAddSerRow = (tableIndex) => {
    const updatedTableData = [...tableData];
    updatedTableData[tableIndex].services.push({
      s_no: (updatedTableData[tableIndex].services.length + 1).toString(),
      service_name: "",
      service_amount: "",
    });

    const updatedFields = [...updatedTableData[tableIndex].services];

    if (updatedFields.length > 1) {
      updatedTableData[tableIndex].services = updatedFields;
      setTableData(updatedTableData);
    } else {
      console.error("Cannot delete the last row", { tableIndex });
    }
  };

  const handleDeleteServiceRow = (tableIndex, taxIndex) => {
    const updatedTableData = [...tableData];

    if (
      (updatedTableData[tableIndex] &&
        Array.isArray(updatedTableData[tableIndex].services)) ||
      Array.isArray(updatedTableData[tableIndex].taxes)
    ) {
      const updateServiceeee = [...updatedTableData[tableIndex].services];
      const updatedTaxes = [...updatedTableData[tableIndex].taxes];

      if (updateServiceeee.length > 1 || updatedTaxes.length > 1) {
        updateServiceeee.splice(taxIndex, 1);
        const subTotal =
          parseFloat(updatedTableData[tableIndex].sub_total) || 0;
        let total = subTotal;

        updateServiceeee.forEach((tax) => {
          const serv_amt = parseFloat(tax.service_amount) || 0;
          total += serv_amt;
        });

        updatedTaxes.splice(taxIndex, 1);

        updatedTaxes.forEach((tax) => {
          const taxValue = parseFloat(tax.tax_value) || 0;
          const taxType = parseInt(tax.tax_type, 10);

          if (taxType == 1) {
            total += (subTotal * taxValue) / 100;
          } else if (taxType == 2) {
            total += taxValue;
          }
        });

        updatedTableData[tableIndex].taxes = updatedTaxes;

        updatedTableData[tableIndex].services = updateServiceeee;
        updatedTableData[tableIndex].total = total.toFixed(2);
        setTableData(updatedTableData);
      } else {
        console.error("Cannot delete the last tax row", { tableIndex });
      }
    } else {
      console.error("Table index out of bounds or services is not an array", {
        tableIndex,
      });
    }
  };

  const handleCompanyChange = (key, value) => {
    const updatedData = data.map((item) =>
      item.key === key ? { ...item, company: value } : item
    );
    setData(updatedData);
    const newRow = updatedData.find((item) => item.key === key);
    if (newRow) {
      setModalCompany(newRow.company);
    }
  };

  const handleAdditionalPriceChange = (key, value) => {
    const updatedData = data.map((item) =>
      item.key === key ? { ...item, additionalPrice: value } : item
    );
    setData(updatedData);
    const newRow = updatedData.find((item) => item.key === key);
    if (newRow) {
      setAdditionalPrice(newRow.additionalPrice);
    }
  };
  const handleDeleteRowTable = (tableIndex, fieldIndex) => {
    const updatedTableData = [...tableData];

    if (
      updatedTableData[tableIndex] &&
      Array.isArray(updatedTableData[tableIndex].columnsdata)
    ) {
      const updatedFields = [...updatedTableData[tableIndex].columnsdata];

      if (updatedFields.length > 1) {
        // Remove the row
        updatedFields.splice(fieldIndex, 1);
        updatedTableData[tableIndex].columnsdata = updatedFields.map(
          (v, i) => ({ ...v, s_no: `${i + 1}` })
        );

        // Recalculate sub_total after deleting row
        const subTotal = updatedFields
          ?.reduce((acc, row) => acc + parseFloat(row.total_price || 0), 0)
          .toFixed(2);
        updatedTableData[tableIndex].sub_total = subTotal;

        // Recalculate total
        let total = parseFloat(subTotal);

        const taxes = updatedTableData[tableIndex].taxes;
        if (taxes && taxes.length > 0) {
          taxes.forEach((tax) => {
            const taxValue = parseFloat(tax.tax_value) || 0;

            if (tax.tax_type == "1") {
              // Percentage-based tax
              total += (total * taxValue) / 100;
            } else if (tax.tax_type == "2") {
              // Fixed-value tax
              total += taxValue;
            }
          });
        }

        // Update the total field
        updatedTableData[tableIndex].total = total.toFixed(2);

        // Update state
        setTableData(updatedTableData);
      } else {
        console.error("Cannot delete the last row", { tableIndex });
      }
    } else {
      console.error(
        "Table index out of bounds or columnsdata is not an array",
        { tableIndex }
      );
    }
  };

  const initialData = [{ key: 0, company: "", additionalPrice: "" }];
  const [data, setData] = useState(initialData);
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(
    data.length > 1
  );

  const options2 =
    customerData && customerData.length > 0
      ? customerData.map((v) => ({
        value: v.company_name,
        label: v.company_name,
        data: { id: v.id, name: v.company_name }, // Store full object separately

      }))
      : [];

  const TermCoditoptions =
    quotationsssactive && quotationsssactive?.data?.length > 0
      ? quotationsssactive?.data?.map((v) => ({
        value: v.term_description,
        label: v.term_name,
      }))
      : [];

  const currencyOption =
    currencyData && currencyData.length > 0
      ? currencyData.map((v) => ({ value: v.cur_name, label: v.cur_name }))
      : [];

  console.log("first", customerIddd)

  const handleChangee = (value) => {
    const selectedCustomer = options2.find((opt) => opt.value === value)?.data;
    setFilterCustomerIdd(selectedCustomer?.id)
    setCustomerId(selectedCustomer?.name); // Set only the name for display
  };


  const HandleTermCondiotonHadle = (value) => {
    setTermCOndit(value);
  };
  const handleChangeee = async (value) => {
    setCompanyId(value);
    await dispatch(fetchNumberField({ id: value }));

    setNumber_Field(numberfield?.data);
  };

  useEffect(() => {
    handleChangeee(localStorage.getItem("companyName"));
  }, []);

  const handleAddRow = (tableIndex, fieldIndex) => {
    const updatedTableData = [...tableData];
    updatedTableData[tableIndex].columnsdata.push({
      s_no: (updatedTableData[tableIndex].columnsdata.length + 1).toString(),
      description: "",
      qty: 0,
      unit_type: "",
      unit: "",
      unit_price: 0,
      total_price: 0,
    });

    const updatedFields = [...updatedTableData[tableIndex].columnsdata];

    if (updatedFields.length > 1) {
      updatedTableData[tableIndex].columnsdata = updatedFields;
      setTableData(updatedTableData);
    } else {
      console.error("Cannot delete the last row", { tableIndex });
    }

    // setTableData(updatedTableData);
  };

  const handleAddTaxRow = (tableIndex) => {
    const updatedTableData = [...tableData];
    updatedTableData[tableIndex].taxes.push({
      s_no: (updatedTableData[tableIndex].taxes.length + 1).toString(),
      tax_id: "",
      tax_value: "",
      tax_type: "",
    });

    const updatedFields = [...updatedTableData[tableIndex].taxes];

    if (updatedFields.length > 1) {
      updatedTableData[tableIndex].taxes = updatedFields;
      setTableData(updatedTableData);
    } else {
      console.error("Cannot delete the last row", { tableIndex });
    }
  };

  const handleDeleteTaxRow = (tableIndex, taxIndex) => {
    const updatedTableData = [...tableData];

    if (
      updatedTableData[tableIndex] &&
      Array.isArray(updatedTableData[tableIndex].taxes)
    ) {
      const updatedTaxes = [...updatedTableData[tableIndex].taxes];

      // Ensure there is at least one row left
      if (updatedTaxes.length > 1) {
        updatedTaxes.splice(taxIndex, 1);
        const subTotal =
          parseFloat(updatedTableData[tableIndex].sub_total) || 0;
        let total = subTotal;

        updatedTaxes.forEach((tax) => {
          const taxValue = parseFloat(tax.tax_value) || 0;
          const taxType = parseInt(tax.tax_type, 10);

          if (taxType == 1) {
            // Tax type 1: percentage
            total += (subTotal * taxValue) / 100;
          } else if (taxType == 2) {
            // Tax type 2: flat amount
            total += taxValue;
          }
        });

        updatedTableData[tableIndex].taxes = updatedTaxes;
        updatedTableData[tableIndex].total = total.toFixed(2);
        setTableData(updatedTableData);
      } else {
        console.error("Cannot delete the last tax row", { tableIndex });
      }
    } else {
      console.error("Table index out of bounds or taxes is not an array", {
        tableIndex,
      });
    }
  };

  const handleDeleteRow = (key) => {
    const updatedData = data.filter((item) => item.key !== key);
    setData(updatedData);
    if (updatedData.length === 1) {
      setIsDeleteButtonEnabled(false); // Disable the delete button
    }
  };

  const getTaxColumns = (tableIndex) => [
    {
      title: " Table Taxes",
      dataIndex: "taxes",
      key: "taxes",
      fixed: "right",
      render: (text, record, index) => (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Stack spacing={1}>
            <InputLabel
              htmlFor={`option-${index}`}
              style={{ fontWeight: "bold", marginBottom: "0px" }}
            >
              Tax {index + 1}
            </InputLabel>
            <FormControl variant="outlined" fullWidth>
              <Select
                value={record.tax_id} // Use tax_id as the value for Select
                onChange={(e) => {
                  const selectedTaxId = e?.target?.value; // Get the selected id

                  // Find the full tax object that matches the selected id
                  const selectedTax = taxes?.find(
                    (tax) => tax.id == selectedTaxId
                  );

                  // Pass the selected tax object directly
                  handleFieldTaxChange(tableIndex, index, selectedTax);
                }}
                id={`option-${index}`}
                name="roleid"
                input={<OutlinedInput />}
                style={{ minWidth: "200px", marginBottom: "15px" }}
              >
                {taxes &&
                  taxes.length !== 0 &&
                  taxes.map((v) => (
                    <MenuItem key={v.id} value={v.id}>
                      {v.tax_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>

          {/* <ThemeInput
            type="text"
            name="Amounttt*"
            value={record.tax_value} // Use the appropriate value from the record
            disabled={true}
          /> */}
          <ThemeInput
            type="text"
            name="Amount"
            value={
              record.tax_type == 1
                ? `${record.tax_value || 0}%` // Show percentage for tax_type 1
                : record.tax_value || 0 // Show value as is for other tax types
            }
            disabled={true}
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <Space>
          <Button
            style={{ backgroundColor: "#222831", color: "#FFFFFF" }}
            onClick={() => handleAddTaxRow(tableIndex)}
          >
            <PlusOutlined size={25} />
          </Button>
          <Button
            style={{ backgroundColor: "#ED1D24", color: "#FFFFFF" }}
            onClick={() => handleDeleteTaxRow(tableIndex, index)}
            disabled={tableData[tableIndex].taxes.length <= 1}
          >
            <DeleteOutlined size={25} />
          </Button>
        </Space>
      ),
    },
  ];

  const formatNumberWithCommas = (value) => {
    if (!value) return "";
    const cleanValue = value.toString().replace(/,/g, "");
    const parts = cleanValue.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  const getColumns = (tableIndex) => [
    {
      title: "S.no",
      dataIndex: "s_no",
      width: 100,
      key: "s_no",
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Description*",
      dataIndex: "description",
      key: "description",
      fixed: "left",
      render: (text, record, index) => (
        // <Input
        //   value={record.description}
        //   onChange={(e) =>
        //     handleFieldChange(tableIndex, index, "description", e.target.value)
        //   }
        // />
        <ReactQuill
          theme="snow"
          value={record.description}
          onChange={(e) =>
            handleFieldChange(tableIndex, index, "description", e)
          }
          placeholder="Enter description..."
          modules={modules}
          formats={formats}
        />

      ),
    },
    {
      title: "Quantity*",
      dataIndex: "qty",
      key: "qty",
      render: (text, record, index) => (
        <Input
          type="number"
          value={record.qty}
          onChange={(e) => {
            handleFieldChange(
              tableIndex,
              index,
              "qty",
              Math.max(0, parseFloat(e.target.value))
            );
          }}
        />
      ),
    },
    {
      title: "Unit Type",
      dataIndex: "unit_type",
      key: "unit_type",
      fixed: "left",
      render: (text, record, index) => (
        <Input
          value={record.unit_type}
          onChange={(e) =>
            handleFieldChange(tableIndex, index, "unit_type", e.target.value)
          }
        />
      ),
    },
    {
      title: "Unit Price*",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (text, record, index) => (
        <div>
          {/* <Input
            type="text"
            // value={isNaN(record.unit_price) ? 0 : record.unit_price}
            value={formatNumber(isNaN(record.unit_price) ? 0 : record.unit_price)}

            onChange={(e) =>
              handleFieldChange(
                tableIndex,
                index,
                "unit_price",
                Math.max(0, parseFloat(e.target.value))
              )
            }
          /> */}
          <Input
            type="text"
            value={formatNumberWithCommas(record.unit_price || 0)}
            onChange={(e) => {
              const rawValue = parseFormattedNumber(e.target.value);
              if (rawValue === '' || !isNaN(rawValue)) {
                handleFieldChange(
                  tableIndex,
                  index,
                  "unit_price",
                  rawValue === '' ? 0 : rawValue
                );
              }
            }}
          />
        </div>
      ),
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
      render: (text, record, index) => (
        <Input
          type="text"
          // value={isNaN(record.total_price) ? 0 : record.total_price}
          value={formatNumber(isNaN(record.total_price) ? 0 : record.total_price)}
          disabled
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <Space>
          <Button
            style={{ backgroundColor: "#222831", color: "#FFFFFF" }}
            onClick={() => handleAddRow(tableIndex, index)}
          >
            <PlusOutlined size={25} color={"#fff"} />
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "#FFFFFF" }}
            onClick={() => handleDeleteRowTable(tableIndex, index)}
            disabled={tableData[tableIndex].columnsdata.length <= 1}
          >
            <DeleteOutlined size={25} color={"#fff"} />
          </Button>
        </Space>
      ),
    },
  ];

  const renderExpandedRow = (record, tableIndex) => {
    return (
      <Input
        className="expanded-content"
        value={record?.title_name}
        onChange={(e) =>
          handleFieldChange(
            tableIndex,
            +record?.s_no - 1,
            "title_name",
            e.target.value
          )
        }
        style={{
          marginBottom: "5px",
        }}
        placeholder="Enter Heading "
      />
    );
  };

  const handleRowExpand = (expand, record, tableIndex) => {
    const updatedTableData = [...tableData];

    if (
      updatedTableData[tableIndex] &&
      Array.isArray(updatedTableData[tableIndex].columnsdata) &&
      !expand
    ) {
      const updatedFields = [...updatedTableData[tableIndex].columnsdata];
      if (updatedFields[+record?.s_no - 1] !== undefined) {
        const updatedFieldsvalue = updatedFields.map((v, i) => {
          if (i == +record?.s_no - 1) {
            return {
              ...v,
              // title_name: "",
            };
          } else {
            return v;
          }
        });
        updatedTableData[tableIndex].columnsdata = updatedFieldsvalue;
        setTableData(updatedTableData);
      }
    }
  };
  const handleAddRowModal = () => {
    const newRow = {
      key: data.length,
      company: "", // Initialize with Modalcompany
      additionalPrice: "", // Initialize with AdditionalPrice
    };
    setData([...data, newRow]);
    setIsDeleteButtonEnabled(true); // Enable the delete button
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAllTrmActive({ setLoading: setLoading }));
  }, []);

  useEffect(() => {
    if (company_id) {
      setNumber_Field(numberfield?.data);
    }
  }, [company_id, numberfield]);

  useEffect(() => {
    console.log("locationssssda", location?.state)
  }, [location])

  useEffect(() => {
    if (location.state) {
      const payload = { id: location.state?.id };
      dispatch(
        fetchViewQuotation({
          payload,
          setLoading: setLoading,
        })
      );
      handleChangeee(location.state?.company_id);
    }
  }, [location]);

  const handleTableRowSubmit = (tableRowData) => {
    if (Array.isArray(tableRowData)) {
      const updatedRowTableData = tableRowData.map((v) => {
        const singleTableData = { ...v };
        const arrayOfRows = [];

        singleTableData.columnsdata.forEach((row) => {
          const newRow = { ...row }; // Copy the row first to avoid mutation
          if (newRow.title_name) {
            arrayOfRows.push({ s_no: newRow.s_no, title_name: newRow.title_name });
          }
          arrayOfRows.push(newRow);
        });
        singleTableData.columnsdata = arrayOfRows;
        return singleTableData;
      });
      return updatedRowTableData;
    }
  };


  useEffect(() => {
    if (conditionOverAll.includes(1)) {
      setTableData((prevTableData) =>
        prevTableData.map((table) => ({
          ...table, // Preserve the existing data
          taxes: [{ tax_id: "", tax_value: "", tax_type: "" }], // Reset taxes for each table
          discount: "", // Reset discount
          discount_type: "", // Reset discount type
        }))
      );
      setTableData((prevTableData) => {
        return prevTableData.map((table) => {
          // Ensure that `table` has the necessary data
          const updatedSubTotal = calculateSubTotal(table);
          const SubTotalSeparate = calculateSubExternalTotal(table);
          const updatedTotal = calculateTotal(updatedSubTotal, table.taxes);

          return {
            ...table,
            total: updatedTotal,
            sub_total: SubTotalSeparate
          };
        });
      });
    } else if (conditionOverAll.includes(2)) {
      setTableData((prevTableData) =>
        prevTableData.map((table) => ({
          ...table, // Preserve the existing data
          taxes: [{ tax_id: "", tax_value: "", tax_type: "" }], // Reset taxes for each table
        }))
      );
      setTableData((prevTableData) => {
        return prevTableData.map((table) => {
          // Ensure that table has the necessary data
          const updatedSubTotal = calculateSubTotal(table);
          const SubTotalSeparate = calculateSubExternalTotal(table);
          const updatedTotal = calculateTotal(updatedSubTotal, table.taxes, table.discount, table.discount_type);

          return {
            ...table,
            total: updatedTotal,
            sub_total: SubTotalSeparate

          };
        });
      });
    }
  }, [conditionOverAll]);

  const calculateSubTotal = (table) => {
    // Ensure that columnsdata has valid data
    return table.columnsdata.reduce(
      (acc, item) => acc + (item.qty * item.unit_price || 0),
      0
    );
  };

  const calculateSubExternalTotal = (table) => {
    const subtotal = table.columnsdata.reduce(
      (acc, item) => acc + (item.qty * item.unit_price || 0),
      0
    );
    return subtotal.toFixed(2); // Format the result to 2 decimal places
  };


  const calculateTotal = (subTotal, taxes, discount, discountType) => {
    const totalTaxValue = taxes.reduce((acc, tax) => {
      const taxValue = parseFloat(tax.tax_value) || 0;
      const taxType = parseInt(tax.tax_type, 10);

      if (taxType == 1) {
        return acc + (subTotal * taxValue) / 100;
      } else if (taxType == 2) {
        return acc + taxValue;
      }

      return acc;
    }, 0);

    let total = subTotal + totalTaxValue;
    if (discount && discountType) {
      const discountValue = parseFloat(discount) || 0;

      if (discountType == "1") {
        total -= (subTotal * discountValue) / 100;
      } else if (discountType == "2") {
        total -= discountValue;
      }
    }

    return total > 0 ? total.toFixed(2) : "0.00";
  };

  // const calculateTotal = (subTotal, taxes) => {
  //   // Handle cases where taxes might be empty or invalid
  //   const totalTaxValue = taxes.reduce(
  //     (acc, tax) => acc + (parseFloat(tax.tax_value) || 0),
  //     0
  //   );
  //   return subTotal + totalTaxValue;
  // };

  // const GetGrandTotal = () => {
  //   let grandTotal = tableData.reduce((acc, table) => {
  //     return acc + (parseFloat(table.total) || 0);
  //   }, 0);
  //   return grandTotal;
  // };

  const GetGrandTotal = () => {
    // Calculate subtotal from tableData
    const grandTotal = tableData.reduce((acc, table) => {
      return acc + (parseFloat(table.total) || 0);
    }, 0);

    const discountValue = Number(discount) || 0; // Convert discount to a number
    const discountType = String(discount_type); // Convert discount_type to string

    if (grandTotal > 0 && discountValue > 0) {
      if (discountType === "1") {
        // Percentage discount
        const percentageDiscount = (grandTotal * discountValue) / 100;
        return (percentageDiscount).toFixed(2);
      } else if (discountType === "2") {
        // Flat discount
        return (discountValue).toFixed(2);
      }
    }

    return grandTotal.toFixed(2); // Return the grand total if no discount applies
  };

  function calculateDiscount() {

    const subtotal = tableData.reduce((acc, table) => {
      return acc + (parseFloat(table.total) || 0);
    }, 0);
    const discountValues = Number(discount) || 0; // Convert discount to a number
    const discountType = String(discount_type); // Convert discount_type to string
    let discountValue = 0;
    if (discountType == 1) {
      // Calculate percentage discount
      discountValue = (subtotal * discountValues) / 100;
    } else if (discountType == 2) {
      // Fixed amount discount
      discountValue = discountValues;
    }

    return discountValue;
  }

  const calculateGrandTotal = () => {
    let grandTotal = tableData.reduce((acc, table) => {
      return acc + (parseFloat(table.total) || 0);
    }, 0);
    if (discount_type == "1") {
      grandTotal -= (grandTotal * discount) / 100;
    } else if (discount_type == "2") {
      grandTotal -= discount;
    }
    const totalTaxes = calculateTotalTaxes(grandTotal);
    grandTotal += totalTaxes;

    return grandTotal;
  };

  const calculateTotalTaxes = (grandTotal) => {
    if (!Array.isArray(OverAllTax) || !OverAllTax.length) {
      return 0;
    }
    return OverAllTax.reduce((acc, tax) => {
      if (!tax || typeof tax !== "object") {
        return acc; // Skip invalid entries
      }
      const taxValue = parseFloat(tax.tax_value) || 0;
      if (tax.tax_type === "1") {
        return acc + (grandTotal * taxValue) / 100;
      } else if (tax.tax_type === "2") {
        return acc + taxValue;
      }
      return acc;
    }, 0);
  };


  // const handleCheckboxChange = (event, value) => {
  //   const isChecked = event.target.checked;

  //   setCondtionOvelAlling((prev) => {
  //     if (isChecked) {
  //       return [...prev, value];
  //     } else {
  //       return prev.filter((v) => v !== value);
  //     }
  //   });
  // };

  const handleCheckboxChange = (event, value) => {
    const isChecked = event.target.checked;

    setCondtionOvelAlling((prev) => {
      if (value === 1 && isChecked) {
        return [...new Set([...prev, value, 2])];
      } else if (!isChecked && value === 1) {
        return prev.filter((v) => v !== 1 && v !== 2);
      } else if (isChecked) {
        return [...prev, value];
      } else {
        return prev.filter((v) => v !== value);
      }
    });
  };

  const handleTableRowNotCSVSubmit = (tableRowData) => {
    if (Array.isArray(tableRowData)) {
      const updatedRowTableData = tableRowData.map((v) => {
        const singleTableData = { ...v };
        const arrayOfRows = [];
        singleTableData.columnsdata.forEach((row) => {
          arrayOfRows.push(row);
          if (row.title_name) {
            arrayOfRows.push({ s_no: row?.s_no, title_name: row?.title_name });
          }
        });
        singleTableData.columnsdata = arrayOfRows;

        return singleTableData;
      });

      return updatedRowTableData;
    }
  };


  const handleSubmit = () => {
    const payload = {
      // pdf_type: pdfType,
      company_id: [
        localStorage.getItem("companyName"),
        ...data.map((field) => field.company).filter((val) => val),
      ],
      customer_id: customer_id,
      note: note,
      valid_till: expDate,
      number,
      quotation_type: quotation_type,
      discount: discount,
      OverAllTax: OverAllTax,
      discount_type: discount_type,
      tableData: csvCOndition ? handleTableRowSubmit(tableData) : handleTableRowNotCSVSubmit(tableData),
      terms_conditions: TermDecccc,
      projects: assignProjects,
      payment_term,
      is_lock: is_lock,
      date,
      currency,
      overAll_tax_discount_condition:
        conditionOverAll.includes(1) && conditionOverAll.includes(2)
          ? "1"
          : conditionOverAll.includes(1)
            ? "1"
            : conditionOverAll.includes(2)
              ? "2"
              : "3",

      // tax_id: options.map((option) => option.tax),
      is_parent: [
        0,
        ...data.map((field) => (field.company ? "1" : "")).filter(Boolean),
      ],
      additional_price: [
        "0",
        ...data.map((field) => field.additionalPrice).filter(Boolean),
      ],
    };
    if (
      localStorage.getItem("companyName") &&
      customer_id.trim() &&
      quotation_type &&
      payment_term.trim() &&
      date &&
      currency &&
      expDate
    ) {
      if (!location.state) {
        dispatch(
          fetchStoreQuotation({
            payload,
            setLoading: setLoading,
          })
        );
        setLoading(true);
      } else {
        dispatch(
          updateStoreQuotation({
            payload: { ...payload, qt_id: location.state.id },
            setLoading: setLoading,
          })
        );
        setLoading(true);
      }
    } else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
    }
  };

  const HandleCustomerPlusIcon = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AddTable = () => {
    setTableData([
      ...tableData,
      {
        columnsdata: [
          {
            s_no: "1",
            description: "",
            qty: 0,
            unit_type: "",
            unit: "",
            unit_price: 0,
            total_price: 0,
            title_name: ""
          },
        ],
        sub_total: "",
        taxes: [{ tax_id: "", tax_value: "", tax_type: "" }],
        services: [{ service_name: "", service_amount: "" }],
        total: "",
        table_title: "",
        discount: "",
        discount_type: "",
        sub_title: "",
      },
    ]);
  };

  const handleTableTitleChange = (index, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].table_title = value;
    setTableData(updatedTableData);
  };

  const handleTableSubTitleChange = (index, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].sub_title = value;
    setTableData(updatedTableData);
  };

  const DeleteTable = (index) => {
    const newTableData = tableData.filter((_, i) => i !== index);
    setTableData(newTableData);
  };

  const SubmitCSVINcr = () => {
    if (!uploadedData) {
      return alert("Please upload a file");
    }

    const incrementedData = uploadedData.map((table) => {
      const updatedColumnsdata = table.columnsdata.map((row) => {
        const updatedUnitPrice =
          parseFloat(row.unit_price) +
          (parseFloat(row.unit_price) * parseFloat(increaseAmount)) / 100;
        const updatedTotalPrice = updatedUnitPrice * row.qty;
        return {
          ...row,
          unit_price: updatedUnitPrice.toFixed(2),
          total_price: updatedTotalPrice.toFixed(2),
        };
      });

      const updatedSubTotal = updatedColumnsdata
        ?.reduce((acc, row) => acc + parseFloat(row.total_price), 0)
        .toFixed(2);

      return {
        ...table,
        columnsdata: updatedColumnsdata,
        sub_total: updatedSubTotal,
      };
    });

    // setTableData(incrementedData);
    // setTableData(prevData => [...prevData, ...incrementedData]);  // Ab ye purana or naya dono data rakhega
    setTableData((prevData) => {
      // Check if `prevData` has all required fields in any `columnsdata` object
      const hasRequiredFields = prevData.some((table) =>
        table?.columnsdata?.some(
          (column) =>
            column?.description &&
            column?.qty &&
            column?.unit_price
        )
      );

      // Set the appropriate data based on the condition
      return hasRequiredFields ? [...prevData, ...incrementedData] : [...incrementedData];
    });


    closeModal();
  };
  const UploaCSVHandle = () => {
    setModalVisible(true);
  };

  const handleInputChange = (e, index, field) => {
    const { value } = e.target;
    setDiscount_DIs_COnd(false);
    setTableData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [field]: value,
      };
      return updatedData;
    });
  };



  const getSummmaryColumns = [
    {
      title: "Table Title",
      dataIndex: "table_title",
      key: "table_title",
    },
    {
      title: "Tax Price",
      dataIndex: "taxes",
      key: "taxes",
      render: (text, record) => {
        return record?.total > 0 ? record?.total - record?.sub_total || 0 : ""; // Ensure it returns 0 if there are no applicable taxes
      },
    },
    {
      title: "Discount Price",
      dataIndex: "discount",
      key: "discount",
      render: (text, record) => {
        const discountValue = Number(record?.discount) || 0; // Convert discount to a number
        const discountType = String(record?.discount_type); // Convert discount_type to string
        const subTotal = Array.isArray(record?.columnsdata) && record?.columnsdata?.length > 0
          ? record.columnsdata.reduce((sum, item) => {
            // Convert total_price to number, removing quotes if present
            const price = Number(item.total_price?.replace?.(/"/g, '') || 0);
            return sum + price;
          }, 0)
          : 0;

        if (subTotal > 0 && discountValue > 0) {
          if (discountType == "1") {
            const percentageDiscount = (subTotal * discountValue) / 100;
            return percentageDiscount.toFixed(2);
          } else if (discountType == "2") {
            return discountValue.toFixed(2); // Flat discount
          }
        }
        return ""; // Return empty if no valid discount
      },
    },
    {
      title: "Service Price",
      dataIndex: "services",
      key: "services",
      render: (text, record) => {
        if (record.services && record.services.length > 0) {
          let totalServiceAmount = 0;

          if (record.services.length === 1) {
            totalServiceAmount = parseFloat(record.services[0].service_amount);
          } else {
            totalServiceAmount = record.services.reduce((sum, service) => {
              return sum + parseFloat(service.service_amount);
            }, 0);
          }

          return totalServiceAmount;
        }

        return "";
      },
    },
    {
      title: "Sub Total",
      dataIndex: "sub_total",
      key: "sub_total",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  const getServicesColumns = (tableIndex) => [
    {
      title: "Table Services",
      dataIndex: "services",
      key: "services",
      fixed: "right",
      render: (text, record, index) => (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <ThemeInput
            type="text"
            name="Service*"
            value={record.service_name}
            onChange={(e) =>
              handleFieldSERvicesChange(
                tableIndex,
                index,
                "service_name",
                e.target.value
              )
            }
          />

          <ThemeInput
            type="number"
            name="Amount*"
            value={record.service_amount}
            onChange={(e) =>
              handleFieldSERvicesChange(
                tableIndex,
                index,
                "service_amount",
                e.target.value
              )
            }
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <Space>
          <Button
            style={{ backgroundColor: "#222831", color: "#FFFFFF" }}
            onClick={() => handleAddSerRow(tableIndex)}
          >
            <PlusOutlined size={25} color={"#fff"} />
          </Button>
          <Button
            style={{ backgroundColor: "red", }}
            onClick={() => handleDeleteServiceRow(tableIndex, index)}
            disabled={tableData[tableIndex].services.length <= 1}
          >
            <DeleteOutlined size={25} color={"#fff"} />
          </Button>
        </Space>
      ),
    },
  ];

  const handleDiscountChange = (e) => {
    let value = parseFloat(e.target.value ? e.target.value : 0);
    if (discount_type === "1") {
      if (value >= 0 && value <= 100) {
        setdiscount(value);
      }
    } else if (discount_type === "2") {
      setdiscount(value);
    }
  };

  useEffect(() => {
    if (
      location.state &&
      viewQuotionData &&
      viewQuotionData?.main_quotation &&
      viewQuotionData?.main_quotation?.quo_Refs?.length !== 0
    ) {

      setdiscount(location.state?.discount);
      setdiscountType(location.state?.discount_type);
      setCondtionOvelAlling(
        location.state?.overAllCondition == 1
          ? [1, 2]
          : location.state?.overAllCondition == 2
            ? [2]
            : []
      );
      setOverAllTax(JSON.parse(location.state?.tax_id))
      setDiscount_DIs_COnd(true)
      const updatedData = viewQuotionData?.main_quotation?.quo_Refs?.map(
        (item) => {
          const qt_items_no_title = item.qt_items.filter((v) => !v?.title_name);
          const qt_items_with_title = item.qt_items.filter(
            (v) => v?.title_name
          );

          const qt_item_combine = qt_items_no_title.map((v) => {
            const qt_match = qt_items_with_title.find(
              (wt) => wt?.s_no == v?.s_no
            );
            if (qt_match) {
              return { ...v, title_name: qt_match?.title_name };
            } else {
              return v;
            }
          });
          return {
            columnsdata: qt_item_combine,
            defaultExpand: qt_items_with_title.map((v) => v?.s_no),
            total: item.table_grand_total,
            table_title: item.table_heading,
            taxes: item?.table_tax_id && Array.isArray(JSON.parse(item?.table_tax_id)) && JSON.parse(item?.table_tax_id)?.length > 0
              ? JSON.parse(item?.table_tax_id)?.map((tax) => {
                return {
                  ...tax,
                  id: tax.tax_id, // Rename tax_id to id
                  tax_value: tax.tax_value,
                  tax_name: tax.tax_name,
                  tax_type: tax.tax_type,
                };
              })
              : [{ tax_id: "", tax_value: "", tax_type: "" }],
            services: item?.service_id && Array.isArray(JSON.parse(item?.service_id)) && JSON.parse(item?.service_id)?.length > 0
              ? JSON.parse(item?.service_id)?.map((ser) => {
                return {
                  service_name: ser.service_name, // Rename tax_id to id
                  service_amount: ser.service_amount, // Rename tax_id to id
                };
              }) : [{ service_name: "", service_amount: "" }],
            sub_title: item.table_sub_heading,
            sub_total: item.table_sub_total,
            discount: item.table_discount,
            discount_type: item.table_discount_type,
          };
        }
      );
      setTableData(updatedData);
    }
  }, [location.state, viewQuotionData]);

  // const handleReset = (tableIndex) => {
  //   const updatedTableData = [...tableData];
  //   if (updatedTableData[tableIndex] && Array.isArray(updatedTableData[tableIndex].columnsdata)) {
  //     const updatedFields = [...updatedTableData[tableIndex].columnsdata];
  //     const totalSum = updatedFields.reduce((sum, item) => {
  //       const price = Number(item.total_price?.replace?.(/"/g, '') || 0);
  //       return sum + price;
  //     }, 0);
  //     updatedTableData[tableIndex]?.total = totalSum
  //     updatedTableData[tableIndex]?.sub_total = totalSum
  //   }
  // };

  const handleCalculate = (index) => {
    recalculateTotals(index);
    setDiscount_DIs_COnd(true)
  };

  const recalculateTotals = (index) => {
    const updatedTableData = [...tableData];
    const updatedEntry = { ...updatedTableData[index] };

    let subTotal = parseFloat(updatedEntry.sub_total) || 0;
    const discount = parseFloat(updatedEntry.discount) || 0;
    const discountType = updatedEntry.discount_type;
    const taxes = updatedEntry.taxes || [];
    const services = updatedEntry.services || [];

    if (discountType == "2") {
      subTotal -= discount;
    } else if (discountType == "1") {
      subTotal -= (subTotal * discount) / 100;
    }

    subTotal = Math.max(subTotal, 0);
    updatedEntry.sub_total = subTotal.toFixed(2);

    let total = subTotal;
    taxes.forEach((tax) => {
      const taxValue = parseFloat(tax.tax_value) || 0;
      const taxType = parseInt(tax.tax_type, 10);
      if (taxType === 1) {
        total += (total * taxValue) / 100;
      } else if (taxType === 2) {
        total += taxValue;
      }
    });

    services.forEach((service) => {
      const serviceAmount = parseFloat(service.service_amount) || 0;
      total += serviceAmount;
    });

    total = Math.max(total, 0);
    updatedEntry.total = total.toFixed(2);

    updatedTableData[index] = updatedEntry;
    setTableData(updatedTableData);
  };

  const handleFieldOverAllTaxChange = (index, field, value) => {
    const updatedTax = [...OverAllTax];
    updatedTax[index][field] = value;
    setOverAllTax(updatedTax);
  };

  const handleAddOverAllTaxRow = () => {
    setOverAllTax([...OverAllTax, { tax_id: "", tax_value: "", tax_type: "" }]);
  };

  const handleDeleteOverAllTaxRow = (index) => {
    const updatedTax = OverAllTax.filter((_, i) => i !== index);
    setOverAllTax(updatedTax);
  };

  const OverAllApplyTaxColumns = [
    {
      title: "Tax*",
      dataIndex: "taxes",
      key: "taxes",
      fixed: "right",
      render: (text, record, index) => (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Stack spacing={1}>
            <InputLabel
              htmlFor={`option-${index}`}
              style={{ fontWeight: "bold", marginBottom: "0px" }}
            >
              Tax {index + 1}
            </InputLabel>
            <FormControl variant="outlined" fullWidth>
              <Select
                value={record.id} // Use the id as the value for Select
                onChange={(e) => {
                  const selectedTaxId = e?.target?.value; // Get the selected id
                  const selectedTax = taxes?.find(
                    (tax) => tax.id == selectedTaxId
                  );
                  handleFieldOverAllTaxChange(index, "tax_id", selectedTax?.id);
                  handleFieldOverAllTaxChange(
                    index,
                    "tax_value",
                    selectedTax?.tax_value
                  );
                  handleFieldOverAllTaxChange(
                    index,
                    "tax_type",
                    selectedTax?.tax_type
                  );
                }}
                id={`option-${index}`}
                name="roleid"
                input={<OutlinedInput />}
                style={{ minWidth: "200px", marginBottom: "15px" }}
              >
                {taxes &&
                  taxes.length !== 0 &&
                  taxes.map((v) => (
                    <MenuItem key={v.id} value={v.id}>
                      {v.tax_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>

          <ThemeInput
            type="text"
            name="Amount*"
            // value={record.tax_value}
            value={
              record.tax_type == 1
                ? `${record.tax_value || 0}%` // Show percentage for tax_type 1
                : record.tax_value || 0 // Show value as is for other tax types
            }
            disabled={true}
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <Space>
          <Button
            style={{ backgroundColor: "lightblue" }}
            onClick={() => handleAddOverAllTaxRow()}
          >
            <PlusOutlined size={25} color={"#fff"} />
          </Button>
          <Button
            style={{ backgroundColor: "red" }}
            onClick={() => handleDeleteOverAllTaxRow(index)}
            disabled={OverAllTax.length <= 1}
          >
            <DeleteOutlined size={25} color={"#fff"} />
          </Button>
        </Space>
      ),
    },
  ];

  const handleAddProject = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    const filteredProjects = customerIddd
      ? allProjData?.data?.filter((e) => e?.customer_id === customerIddd)
      : allProjData?.data;

    if (location.state) {
      setAssignProjects(viewQuotionData?.projects?.[0]?.id)
    } else if (filteredProjects?.length === 1) {
      setAssignProjects(filteredProjects[0].id);
    }
  }, [customerIddd, allProjData, viewQuotionData]);

  return (
    <>
      <Spin spinning={loading ? true : companyData ? false : true}>
        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={12}  >
            <MainCard className="mb-3">
              <div className="row" style={{
                borderRadius: "10px",
                border: " 1px solid #80808052",
                padding: "1%",
                marginTop: "2%"
              }}>
                <h5 style={{
                  marginTop: '-1.8%',
                  background: 'white'
                }}>
                  Basic Info
                </h5>
                {location.state && (
                  <div className="col-md-4 my-2">
                    <Stack spacing={1}>
                      <InputLabel
                        htmlFor="AssignRole"
                        style={{ fontSize: "13px" }}
                      >
                        Qoutation Number
                      </InputLabel>
                    </Stack>

                    <ThemeInput
                      style={{
                        width: "100%",
                        height: "54px",
                      }}
                      value={location.state?.quote_num}
                    />
                  </div>
                )}
                <div className="col-md-4 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      Company*
                    </InputLabel>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <SearchTextSelect
                      handleChangeValue={handleChangee}
                      options={options2}
                      value={customer_id}
                    />
                    <AddIcon
                      style={{ cursor: "pointer", marginRight: "5px" }}
                      onClick={HandleCustomerPlusIcon}
                    />
                  </Stack>
                </div>

                <div className="col-md-4 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      Quotation Type*
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        displayEmpty
                        input={<OutlinedInput />}
                        style={{ height: "52px" }}
                        value={quotation_type}
                        onChange={(e) => setquotation_type(e.target.value)}
                      >
                        {/* You can add your select options here */}
                        <MenuItem disabled value="">
                          <em>Select Quotation Type</em>
                        </MenuItem>

                        <MenuItem value="1">Recieving</MenuItem>
                        <MenuItem value="2">ADHOC</MenuItem>
                        <MenuItem value="3">Quotation Vice</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </div>

                <div className="col-md-4 my-2">
                  <ThemeInput
                    type="text"
                    name="Quotation Serial Number*"
                    value={number}
                    disabled={true}
                    onChange={(e) => setNumber_Field(e.target.value)}
                  />
                </div>
                <div className="col-md-4 my-2">
                  <ThemeInput
                    type="date"
                    name="Date*"
                    value={date}
                    onChange={(e) => setdate(e.target.value)}
                  />
                </div>
                <div className="col-md-4 my-2">
                  <ThemeInput
                    type="date"
                    name="Expiry Date*"
                    value={expDate}
                    onChange={(e) => setExpDate(e.target.value)}
                  />
                </div>

                <div className="col-md-4 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      Currency*
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth></FormControl>
                  </Stack>

                  <SelectAnt
                    showSearch
                    style={{
                      width: "100%",
                      height: "50px",
                    }}
                    onChange={(value) => setcurrency(value)}
                    tokenSeparators={[","]}
                    options={currencyOption}
                    value={currency}
                    placeholder="Select Currency"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </div>
                <div className="col-md-4 my-2">
                  <ThemeInput
                    name="Payment Term*"
                    value={payment_term}
                    placeholder="Payment Term"
                    onChange={(e) => setpayment_term(e.target.value)}
                  />
                </div>

                <div className="col-md-4 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      File Lock
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        displayEmpty
                        input={<OutlinedInput />}
                        style={{ height: "52px" }}
                        value={is_lock}
                        onChange={(e) => {
                          setIsLock(e.target.value);
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>Select</em>
                        </MenuItem>
                        {/* You can add your select options here */}
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="5">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </div>
                <div className="col-md-4 mt-4 d-flex align-items-center">
                  <Stack spacing={0} sx={{ flexGrow: 1 }}>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        displayEmpty
                        input={<OutlinedInput />}
                        style={{ height: "52px" }}
                        value={assignProjects}
                        onChange={(e) => setAssignProjects(e.target.value)}
                      >
                        <MenuItem value="">
                          <em>Select Project</em>
                        </MenuItem>
                        {(customerIddd
                          ? allProjData?.data?.filter((e) => e?.customer_id === customerIddd)
                          : allProjData?.data
                        )?.map((project) => (
                          <MenuItem key={project.id} value={project.id}>
                            {project.project_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  <button className="btn btn-primary ms-2" style={{ height: "52px" }} onClick={handleAddProject}>
                    <PlusOutlined />
                  </button>
                </div>



                <div className="col-md-4 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      Term Conditons Select*
                    </InputLabel>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <SearchTextSelect
                      handleChangeValue={HandleTermCondiotonHadle}
                      options={TermCoditoptions}
                      value={termconditonGet}
                    />
                  </Stack>
                </div>

                <div className="col-md-4 my-2">
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="company">Note*</InputLabel>
                      <FormControl variant="outlined" fullWidth>
                        <TextArea
                          rows={3}
                          placeholder="Enter Note"
                          name="Note*"
                          value={note}
                          allowClear
                          onChange={(e) => setNotee(e.target.value)}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                </div>
                <div className="col-md-4 my-2">
                  <label className="mr-3">Terms Conditions</label>
                  <div style={{ height: "auto", overflowY: "scroll" }}>
                    <ReactQuill
                      theme="snow"
                      value={TermDecccc}
                      onChange={SetTermCondiDescri}
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "10px" }}>
                <Button
                  className="px-4"
                  size="small"
                  variant="contained"
                  onClick={UploaCSVHandle}
                  style={{
                    backgroundColor: "#ed1d24",
                    padding: "8px",
                    fontFamily: "poppins",
                    marginTop: "0px",
                  }}
                >
                  Upload CSV
                </Button>
              </div>

              {tableData?.map((v, index) => {
                return (
                  <>
                    <div key={index} style={{
                      borderRadius: "10px",
                      border: " 1px solid #80808052",
                      padding: "1%",
                      marginTop: "2%"
                    }}>

                      <div >
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <h4 style={{
                            marginTop: '-2.5%',
                            background: 'white'
                          }}>
                            No: {index + 1}
                          </h4>
                          <div style={{
                            float: "right", marginTop: '-3%',
                            background: 'white'
                          }}>
                            <Button
                              variant="contained"
                              style={{ marginRight: "20px", backgroundColor: "red", color: "white" }}
                              onClick={AddTable}
                            >
                              Add Table
                            </Button>
                            <Button
                              onClick={() => DeleteTable(index)}
                              style={{ marginRight: "10px", backgroundColor: "blue", color: "white" }}
                              variant="contained"
                              disabled={tableData?.length <= 1}
                            >
                              Delete Table
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-md-12 my-2">
                          <ThemeInput
                            name="Add Title Heading"
                            placeholder="Add Title Heading"
                            value={v.table_title} // Accessing from map data
                            onChange={(e) =>
                              handleTableTitleChange(index, e.target.value)
                            } // Update title on change
                          />
                        </div>
                        <div className="row my-2" style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          marginLeft: "1px",
                          fontFamily: "Sicpa",
                        }}
                        >
                          <div className="col-md-1" style={{
                            width: '3.2%',
                            background: '#fafaf',
                            padding: '0',
                            borderRadius: '6px 0px 0px 6px',
                            textAlign: 'center',
                            textAnchor: 'middle',
                            border: ' 1px solid #d9d9d9'
                          }}>
                            <ArrowDownwardIcon sx={{ marginTop: "13px" }} size={10} />
                          </div>
                          <div className="col-md-2 " style={{
                            width: '6.9%',
                            background: '#fafaf',
                            padding: '1%',
                            textAlign: 'center',
                            textAnchor: 'middle',
                            border: ' 1px solid #d9d9d9'
                          }}>
                            S.no
                          </div>
                          <div className="col-md-2 " style={{
                            width: '15%',
                            background: '#fafaf',
                            padding: '1%',
                            textAlign: 'center',
                            textAnchor: 'middle',
                            border: ' 1px solid #d9d9d9'
                          }}>
                            Description*
                          </div>
                          <div className="col-md-2 " style={{
                            width: '15%',
                            background: '#fafaf',
                            padding: '1%',

                            textAlign: 'center',
                            textAnchor: 'middle',
                            border: ' 1px solid #d9d9d9'
                          }}>
                            Quantity*
                          </div>
                          <div className="col-md-2 " style={{
                            width: '15%',
                            background: '#fafaf',
                            padding: '1%',

                            textAlign: 'center',
                            textAnchor: 'middle',
                            border: ' 1px solid #d9d9d9'
                          }}>
                            Unit Type
                          </div>
                          <div className="col-md-2 " style={{
                            width: '15%',
                            background: '#fafaf',
                            padding: '1%',

                            textAlign: 'center',
                            textAnchor: 'middle',
                            border: ' 1px solid #d9d9d9'
                          }}>
                            Unit Price*
                          </div>
                          <div className="col-md-2 " style={{
                            width: '15%',
                            background: '#fafaf',
                            padding: '1%',

                            textAlign: 'center',
                            textAnchor: 'middle',
                            border: ' 1px solid #d9d9d9'
                          }}>
                            Total Price
                          </div>
                          <div className="col-md-2 " style={{
                            width: '14.9%',
                            background: '#fafaf',
                            padding: '1%',
                            borderRadius: '0px 10px 10px 0px',
                            textAlign: 'center',
                            textAnchor: 'middle',
                            border: ' 1px solid #d9d9d9'
                          }}>
                            Action
                          </div>
                        </div>

                        <div className="col-md-12 my-2">
                          <ThemeInput

                            value={v.sub_title} // Accessing from map data
                            onChange={(e) =>
                              handleTableSubTitleChange(index, e.target.value)
                            } // Update title on change
                            placeholder="Enter Heading"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <Table
                          className="rounded"
                          bordered={true}
                          pagination={false}
                          columns={getColumns(index)}
                          dataSource={v?.columnsdata} // Make sure to use the correct dataSource
                          scroll={{ x: 1100 }}
                          showHeader={false} // Hide the table headers
                          expandable={{
                            expandedRowRender: (record) => renderExpandedRow(record, index),
                            onExpand: (expanded, record) => handleRowExpand(expanded, record, index),
                            expandIcon: ({ expanded, onExpand, record }) => (
                              <Tooltip title={expanded ? "Click to Remove Heading" : "Click to Add Heading"}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={e => onExpand(record, e)}>
                                  {expanded ? (
                                    <>
                                      <CloseOutlined style={{ color: 'red' }} />
                                    </>
                                  ) : (
                                    <>
                                      <PlusOutlined />
                                    </>
                                  )}
                                </div>
                              </Tooltip>
                            )
                          }}
                          defaultExpandedRowKeys={false}
                          rowKey={"s_no"}
                        />
                      </div>

                      <div className="row d-flex justify-content-between mt-3">
                        <div className="col-md-4 my-2">
                          <ThemeInput
                            name="Sub Total"
                            type="number"
                            disabled={true}
                            value={v.sub_total} // Accessing from map data
                            placeholder="Total"
                          />
                        </div>
                        <div className="col-md-4 my-2">
                          <Stack spacing={1}>
                            <InputLabel
                              htmlFor="AssignRole"
                              style={{ fontSize: "13px" }}
                            >
                              Table Discount Type*
                            </InputLabel>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                displayEmpty
                                input={<OutlinedInput />}
                                disabled={conditionOverAll.includes(1)}
                                style={{ height: "52px" }}
                                value={v.discount_type}
                                onChange={(e) =>
                                  handleInputChange(e, index, "discount_type")
                                }
                              >
                                <MenuItem value="">
                                  <em>Select Discount Type</em>
                                </MenuItem>
                                <MenuItem value="1">Percentage %</MenuItem>
                                <MenuItem value="2">Fix</MenuItem>
                              </Select>
                            </FormControl>
                          </Stack>
                        </div>
                        <div className="col-md-4 my-2" style={{ display: "flex" }}>
                          <ThemeInput
                            name="Table Discount*"
                            type="number"
                            // value={v.discount}
                            value={
                              v?.discount_type == 1
                                ? `${v?.discount || 0}%` // Show percentage for tax_type 1
                                : v?.discount || 0 // Show value as is for other tax types
                            }
                            placeholder="Discount"
                            disabled={conditionOverAll.includes(1)}
                            onChange={(e) =>
                              handleInputChange(e, index, "discount")
                            }
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ marginTop: "40px", width: "40px", marginLeft: "20px", height: "36px" }}
                            onClick={() => handleCalculate(index)}
                            disabled={discount_dis_cond}
                          >
                            Apply
                          </Button>
                          {/* <Button
                            variant="contained"
                            color="primary"
                            sx={{ marginTop: "40px", width: "40px", marginLeft: "20px", height: "36px" }}
                            onClick={() => handleReset(index)}
                          >
                            Reset
                          </Button> */}
                        </div>
                        <div
                          className="col-md-3 my-2"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                        </div>
                      </div>

                      <div className="row justify-content-end">
                        <div className="col-md-6 my-2">
                          <Table
                            className="rounded"
                            pagination={false}
                            columns={getTaxColumns(index)}
                            dataSource={v?.taxes} // Make sure to use the correct dataSource
                            scroll={{ x: 300 }}
                            style={{ maxWidth: "700px", margin: "0 auto" }} // Set max width and center alignment
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <Table
                            className="rounded"
                            pagination={false}
                            columns={getServicesColumns(index)}
                            dataSource={v?.services} // Make sure to use the correct dataSource
                            scroll={{ x: 300 }}
                            style={{ maxWidth: "700px", margin: "0 auto" }} // Set max width and center alignment
                          />
                        </div>

                      </div>
                      <div className="row justify-content-end">
                        <div className="col-md-6 my-2 mt-auto">
                          <ThemeInput
                            name="Total"
                            type="number"
                            disabled={true} // Disabled input, cannot be edited by the user
                            value={v?.total}
                            // value={(() => {
                            //   const total = Number(v?.total) || 0; // Ensure total is a number, default to 0 if not a valid number

                            //   if (v.discount_type == "1") {
                            //     // Discount Type 1: Percentage
                            //     const percentageDiscount =
                            //       (total * v.discount) / 100;
                            //     return (total - percentageDiscount).toFixed(2); // Subtract the discount from total
                            //   } else if (v.discount_type == "2") {
                            //     // Discount Type 2: Flat
                            //     return (total - v.discount).toFixed(2); // Subtract flat discount
                            //   }

                            //   return total.toFixed(2); // If no discount type is applied, just show the original total
                            // })()} // Calculating the total after applying discount
                            placeholder="Total"
                          />
                        </div>
                      </div>
                    </div >
                  </>
                );
              })}
            </MainCard>

            {tableData?.length <= 1 ? (
              <></>
            ) : (
              <div className="row">
                <Stack spacing={1}>
                  <InputLabel
                    style={{ fontWeight: "bold", marginBottom: "0px" }}
                  >

                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={conditionOverAll.includes(1)}
                            onChange={(e) => handleCheckboxChange(e, 1)}
                            name="overallDiscount"
                            color="primary"
                          />
                        }
                        label="Overall Discount"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={conditionOverAll.includes(2)}
                            disabled={conditionOverAll.includes(1) && conditionOverAll.includes(2)}
                            onChange={(e) => handleCheckboxChange(e, 2)}
                            name="overallTax"
                            color="primary"
                          />
                        }
                        label="Overall Tax"
                      />
                    </FormGroup>
                  </FormControl>
                </Stack>
              </div>
            )}

            <div className="row">
              {conditionOverAll.includes(1) && (
                <>
                  <div className="col-md-4 my-2">
                    <Stack spacing={1}>
                      <InputLabel
                        htmlFor="AssignRole"
                        style={{ fontSize: "13px" }}
                      >
                        Discount Type*
                      </InputLabel>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          displayEmpty
                          input={<OutlinedInput />}
                          style={{ height: "52px" }}
                          value={discount_type}
                          onChange={(e) => {
                            setdiscount(0);
                            setdiscountType(e.target.value);
                          }}
                        >
                          <MenuItem value="">
                            <em>Select Discount Type</em>
                          </MenuItem>
                          <MenuItem value="1">Percentage %</MenuItem>
                          <MenuItem value="2">Fix</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </div>
                  <div className="col-md-4 my-2">
                    <ThemeInput
                      type="number"
                      name="Discount*"
                      // value={discount !== 0 ? discount : ""}
                      value={
                        discount_type == 1
                          ? `${discount || 0}%` // Show percentage for tax_type 1
                          : discount || 0 // Show value as is for other tax types
                      }
                      placeholder="Discount"
                      onChange={handleDiscountChange}
                    />
                  </div>
                </>
              )}

              {conditionOverAll.includes(2) && (
                <div className="col-md-12 my-2">
                  <Table
                    className="rounded"
                    pagination={false}
                    columns={OverAllApplyTaxColumns}
                    dataSource={OverAllTax} // Use the correct dataSource
                    scroll={{ x: 300 }}
                    style={{ maxWidth: "700px", margin: "0 auto" }} // Set max width and center alignment
                  />
                </div>
              )}
            </div>

            <div
              className="row"
              style={{ display: "flex", justifyContent: "left" }}
            >
              <div className="col-md-6">
                <MainCard sx={{ height: "260px", overflowY: "scroll" }}>
                  <Table
                    className="rounded"
                    pagination={false}
                    columns={getSummmaryColumns}
                    dataSource={tableData} // Make sure to use the correct dataSource
                    scroll={{ x: 300 }}
                    style={{ maxWidth: "700px", margin: "auto 0" }} // Set max width and center alignment
                  />
                </MainCard>

              </div>
              <div className="col-md-6">
                <Card
                  style={{ height: "260px" }}
                >
                  <div
                    style={{
                      maxHeight: "calc(40vh - 0px)",
                      overflowY: "hidden",
                      paddingRight: "8px",
                      paddingBottom: "100px",
                    }}
                  >
                    <Card style={{ marginTop: "5px", padding: "16px", backgroundColor: "#f5f5f5", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}>
                      <Text strong style={{ fontSize: "16px" }}>Financial Summary</Text>
                      <div style={{ marginTop: "7px" }}>
                        <Text>Tax: <strong>{conditionOverAll.includes(2) ? (
                          <>
                            {calculateTotalTaxes(
                              calculateGrandTotal() -
                              calculateTotalTaxes(calculateGrandTotal())
                            )}
                            {formatNumber(calculateTotalTaxes(
                              tableData.reduce(
                                (acc, table) => acc + (parseFloat(table.total) || 0),
                                0
                              )
                            ))}
                          </>

                        ) : (
                          formatNumber(tableData.reduce((acc, table) => {
                            return table?.total > 0
                              ? Number(acc) + (table?.total - table?.sub_total || 0)
                              : ""
                          }, 0))
                        )}</strong></Text><br />
                        <Text>Sub Total: <strong>{(() => {
                          let total = tableData.reduce((acc, table) => {
                            return acc + (parseFloat(table.sub_total) || 0);
                          }, 0);

                          if (discount_type == "1") {
                            total = total - (total * discount) / 100;
                          } else if (discount_type == "2") {
                            total = total - discount;
                          }

                          return formatNumber(total); // Format the final total
                        })()}</strong></Text><br />
                        {/* <Text>Discount: <strong>{calculateDiscount()}</strong></Text><br /> */}
                        <Text>Grand Total: <strong>{formatNumber(calculateGrandTotal())}</strong></Text>
                      </div>
                    </Card>
                  </div>
                </Card>
              </div>

            </div>


            {/* Add multiple Companies  */}
            <div className="row my-6">
              <div className=" my-2 text-end">
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Button
                    onClick={handleSubmit}
                    disableElevation
                    className="px-3"
                    sx={{ marginLeft: "20px" }}
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {location.state === null
                      ? "Save Quotation"
                      : "Update Quotation"}
                  </Button>
                </div>

                <Modal
                  title="Add Multiple Companies"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Button type="primary" onClick={handleAddRowModal}>
                    <PlusOutlined /> Add Row
                  </Button>
                  <Table
                    pagination={false}
                    columns={[
                      {
                        title: "Comapany",
                        dataIndex: "company",
                        key: "company",
                        render: (text, record) => (
                          <Stack spacing={0}>
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                displayEmpty
                                input={<OutlinedInput />}
                                style={{ height: "52px" }}
                                value={record?.company}
                                onChange={(e) =>
                                  handleCompanyChange(
                                    record.key,
                                    e.target.value
                                  )
                                }
                              >
                                <MenuItem value="">
                                  <em>Select Company</em>
                                </MenuItem>
                                {companyData &&
                                  companyData?.length > 0 &&
                                  companyData?.map((v, e) => {
                                    return (
                                      <MenuItem value={v.id}>
                                        {v.company_name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Stack>
                        ),
                      },
                      {
                        title: "Additional Price",
                        dataIndex: "Additional Price",
                        key: "Additional Price",
                        render: (text, record) => (
                          <ThemeInput
                            type="number"
                            value={record.additionalPrice}
                            onChange={(e) =>
                              handleAdditionalPriceChange(
                                record.key,
                                e.target.value
                              )
                            }
                          />
                        ),
                      },
                      {
                        title: "Action",
                        key: "action",
                        render: (text, record) => (
                          <Space>
                            <Button
                              onClick={() => handleDeleteRow(record.key)}
                              danger
                              disabled={data.length === 1}
                            >
                              <DeleteOutlined /> Delete
                            </Button>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={data}
                  />
                </Modal>
              </div>
            </div>
            {/* Add multiple companies end here */}
          </Grid>
        </Grid>
      </Spin >


      <AddCompanyModal open={open} handleClose={handleClose} />
      <CreateProjectModal isOpen={modalOpen} handleClose={() => setModalOpen(false)} />

      {/* Modal to Upload CSV */}
      <Modal
        title="Upload CSV"
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
      >
        <div className="row">
          <div className="col-12 my-2">
            <p
              style={{
                marginLeft: "2px",
                color: "black",
                textAlign: "left",
                marginBottom: "6px",
              }}
              className="colorgray"
            >
              Import CSV
            </p>

            <Upload
              customRequest={({ file, onSuccess, onError }) =>
                handleImageUpload(file, onSuccess, onError)
              }
              id={`image-option`}
              listType="picture"
              maxCount={1}
              accept=".xlsx, .xls, .csv"
              showUploadList={true}
            >
              <Button
                fullWidth
                className="flex items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none my-1"
              >
                <UploadOutlined style={{ marginRight: 10 }} /> Upload (Max: 1)
              </Button>
            </Upload>
          </div>
          <div className="col-12">
            <ThemeInput
              name="Increase Amount(%)"
              value={increaseAmount} // Accessing from map data
              onChange={(e) => setIncreaseAmount(e.target.value)}
              placeholder="Amount"
            />
          </div>
          <div>
            <Button
              className="px-4"
              size="large"
              variant="contained"
              onClick={SubmitCSVINcr}
              style={{
                backgroundColor: "#ed1d24",
                padding: "10px",
                fontFamily: "poppins",
                marginTop: "17px",
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal CSV ENDS HERE */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    companyData: state?.company?.companyData?.data,
    customerData: state?.customerdata?.customerData?.data,
    currencyData: state?.currency?.currencyData?.data,
    taxes: state?.taxesdata?.taxes?.data,
    numberfield: state?.customernumberfield?.numberfield,
    viewQuotionData: state?.quotationView?.viewQuotation?.data,
    quotationsssactive: state?.quotationssActiveDetail?.quotationsssactive,
    allProjData: state?.AllProjectsManageReducer?.allProjData,

  };
};
export default connect(mapStateToProps)(CreateQuotation);
