import React from "react";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Select as AntSelect } from "antd";

export default function MultiSelectInput(props) {
  const { onChange, value, name, dataArrayOfOjb, disabled } = props;
  return (
    <Grid item xs={12}>
      <Stack spacing={1}>
        <InputLabel htmlFor="category">Select {name}</InputLabel>
        <AntSelect
          mode="tags"
          style={{
            width: "100%",
            height: "47px",
          }}
          placeholder={"Select" + name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          tokenSeparators={[","]}
        >
          <AntSelect.Option disabled value="">
            <em>Select{name}</em>
          </AntSelect.Option>
          {Array.isArray(dataArrayOfOjb) && dataArrayOfOjb &&
            dataArrayOfOjb?.length !== 0 &&
            dataArrayOfOjb?.map((e, i) => (
              <AntSelect.Option key={i} value={e.id}>
                {name == "Projects" ? e.project_name : e.company_name}
              </AntSelect.Option>
            ))}
        </AntSelect>
      </Stack>
    </Grid>
  );
}
