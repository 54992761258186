import {GET_ALL_LOANS_HISTORY} from "../../constant/ActionType";

export default function LoansHistoryAllReducer(state = {
    loansHistoryData:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case GET_ALL_LOANS_HISTORY:
			return { ...state,
				loansHistoryData: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}

