import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Stack, InputLabel, TextField } from "@mui/material";
import SearchTextSelect from "./selects/SearchTextSelect";
import ThemeInput from "./inputs/ThemeInput";
import { CreateFetchProjects, CustomerDataa } from "../store/action/index";
import { dispatch } from "../store/index";

const CreateProjectModal = ({ isOpen, handleClose, customerData }) => {
    const [loading, setLoading] = useState(false);
    const [customer_id, setCustomer_id] = useState("");
    const [project_name, setProject_Name] = useState("");
    const [description, setDescription] = useState("");
    const [start_date, setStart_Date] = useState("");
    const [end_date, setEnd_Date] = useState("");
    const [endDateError, setEndDateError] = useState("");

    useEffect(() => {
        if (isOpen) {
            setLoading(true);
            dispatch(CustomerDataa({ setLoading: setLoading }));
        }
    }, [isOpen]);

    const handleSubmit = () => {
        if (new Date(start_date) > new Date(end_date)) {
            toast.error("End Date cannot be less than Start Date", { position: "top-right", autoClose: 3000 });
            return;
        }

        if (customer_id !== "" && project_name !== "") {
            setLoading(true);

            const payload = {
                customer_id,
                project_name,
                description,
                start_at: start_date,
                end_at: end_date,
                company_id: localStorage.getItem("companyName"),
            };

            dispatch(CreateFetchProjects({ setLoading, payload, handleClose }));
        } else {
            toast.error("Required Fields are empty", { position: "top-right", autoClose: 3000 });
        }
    };

    const optionss = Array.isArray(customerData?.data)
        ? customerData.data.map((v) => ({ value: v.id, label: v.company_name }))
        : [];

    const handleChangeCustomer = (value) => setCustomer_id(value);
    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        setEnd_Date(newEndDate);
        setEndDateError(new Date(newEndDate) < new Date(start_date) ? "End Date cannot be less than Start Date" : "");
    };

    return (
        <Modal title="Create Project" open={isOpen} onCancel={handleClose} width={600}
            footer={null}>
            <div className="row">
                <div className="col-md-6 my-2">
                    <Stack spacing={1}>
                        <InputLabel htmlFor="AssignRole">Customer Name</InputLabel>
                    </Stack>
                    <SearchTextSelect handleChangeValue={handleChangeCustomer} options={optionss} value={customer_id} />
                </div>
                <div className="col-md-6 my-2">
                    <ThemeInput type="text" name="Project Name*" value={project_name} onChange={(e) => setProject_Name(e.target.value)} />
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 my-2">
                    <ThemeInput type="date" name="Start Date*" value={start_date} onChange={(e) => setStart_Date(e.target.value)} />
                </div>
                <div className="col-md-6 my-2">
                    <ThemeInput type="date" name="End Date*" value={end_date} onChange={handleEndDateChange} min={start_date} />
                    {endDateError && <p style={{ color: "red", fontSize: "12px" }}>{endDateError}</p>}
                </div>
                <div className="col-md-12 my-2">
                    <label htmlFor="description" className="mb-2">Description</label>
                    <TextField
                        multiline
                        rows={5}
                        id="description"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                    />
                </div>
            </div>

            <div style={{ textAlign: "end" }} className="my-3">
                <Button type="primary" onClick={handleSubmit} loading={loading}>Add Project</Button>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    customerData: state?.CustomerrReducer?.customerData,
});

export default connect(mapStateToProps)(CreateProjectModal);
