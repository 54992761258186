import { Box, useMediaQuery } from '@mui/material';
import Search from './Search';
import Profile from './Profile';
import MobileSection from './MobileSection';
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = ({ open }) => {
  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  return (
    <>
      {!downLG && <Search />}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          mt: { xs: 1, lg: 0 },
          // marginRight: "29vw"
        }}
      >
      </Box>
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
